import React from "react";
import "./sec6.css";
import sec6img from "../../../../assets/sec_6.png";
import applelogo from "../../../../assets/Applestore.png";
import playlogo from "../../../../assets/G_play.png";
const Section6 = () => {
  return (
    <div className="section6">
      <div className="submain_sec6_div">
        <div className="section6_img_div">
          <img src={sec6img} alt="" />
        </div>
        <div className="section6_text_div">
          <h1>
            Sadece birkaç tıklamayla <br /> satın alın!
          </h1>
          <p>
            TürkAfrica.Market'ta sadece birkaç basit tıklamayla alışverişin
            rahatlığını keşfedin! Sizi seçimimizi keşfetmeye ve sorunsuz,
            zahmetsiz bir alışveriş deneyimi yaşamaya sıcak bir şekilde davet
            ediyoruz. Keyifli Alışverişler!”
          </p>
          <div className="store_div">
            <p>Uygulama Yakında Burada</p>
            <div className="store_img">
              <img src={applelogo} alt="" />
              <img src={playlogo} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section6;
