import React from "react";
import "./sec8.css";
import sec8img from "../../../../assets/sec_8.png";
import applelogo from "../../../../assets/Apple_store.png";
import playlogo from "../../../../assets/G_play.png";

const Section8 = () => {
  return (
    <div className="Section8" id="Sec8">
      <div className="section8_text_div">
        <div className="heading_text">
          <h1>
            Download The “TürkAfrica<span>&#174;</span> Market App”
          </h1>
          <p>Affordable High-Quality Products & Services</p>
        </div>
        <div className="Section8_img">
          <img src={sec8img} alt="" />
        </div>
        <div className="store_div">
          <p style={{ color: "black", textAlign: "center" }}>
            App Coming Soon on
          </p>
          <div
            className="store_img"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <img src={applelogo} alt="" />
            <img src={playlogo} alt="" />
          </div>
        </div>
        <div className="bottom_text_div">
          <p>
            Discover a comprehensive marketplace where you can acquire an
            extensive range of products essential for both your professional and
            daily needs. Engage with a diverse array of highly skilled
            professionals, each offering specialized services tailored to your
            requirements. Connect with an expansive network of over 1.5 billion
            consumers, and establish valuable relationships with a broad
            spectrum of business entities, including distributors,
            manufacturers, exporters, importers, wholesalers, retailers,
            licensors, licensees, and investors. <b>"TürkAfrica.Market" </b>
            stands as your gateway to a world of opportunities, fostering
            connections and growth in a dynamic, global marketplace.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section8;
