import React from "react";
import "./sec3.css";
import img1 from "../../../../assets/sec_3_1.png";
import img2 from "../../../../assets/sec_3_2.png";
import img3 from "../../../../assets/sec_3_3.png";
import img4 from "../../../../assets/sec_3_4.png";
import img5 from "../../../../assets/sec_3_5.png";
import img6 from "../../../../assets/sec_3_6.png";
import img7 from "../../../../assets/sec_3_7.png";
import img8 from "../../../../assets/sec_3_8.png";
import img9 from "../../../../assets/sec_3_9.png";
import img10 from "../../../../assets/sec_3_10.png";
import img11 from "../../../../assets/sec_3_11.png";
import img12 from "../../../../assets/sec_3_12.png";
import applelogo from "../../../../assets/Applestore.png";
import playlogo from "../../../../assets/G_play.png";
import { Link } from "react-scroll";

const Section3 = () => {
  const data = [
    { id: 1, img: img12, name: "Negócio Agrícola" },
    { id: 2, img: img3, name: "Investidor" },
    { id: 3, img: img4, name: "Produtor de Café" },
    { id: 4, img: img9, name: "Importador de Café" },
    { id: 5, img: img8, name: "Varejista" },
    { id: 6, img: img11, name: "Clínica de Beleza" },
    { id: 7, img: img2, name: "Companhia de Mineração" },
    { id: 8, img: img7, name: "Peças de Reposição Automotivas" },
    { id: 9, img: img10, name: "Empresa de Design de Interiores e Exteriores" },
    { id: 10, img: img6, name: "Atacadista de Fabricação Têxtil" },
    { id: 11, img: img5, name: "Advogado de Negócios/Advogado Corporativo" },
    { id: 12, img: img1, name: "Fabricante de Decoração e Móveis" },
  ];
  return (
    <div className="Section3" id="Sec3">
      <div className="sub_sec3">
        <div className="sec3img_div">
          {data.map((item, i) => {
            return (
              <div className="details_img_box">
                <img src={item.img} alt="" />
                <div className="nametext_div">
                  <h4>{item.name}</h4>
                </div>
              </div>
            );
          })}
        </div>
        <div className="sec3text_div">
          <h1>Obtenha Acesso Premium</h1>
          <p>
            Crie redes, faça negócios e estabeleça parcerias com distribuidores,
            fabricantes, exportadores, importadores, investidores,
            licenciadores, licenciados, atacadistas e varejistas. Além disso,
            você pode vender seus produtos ou oferecer seus serviços diretamente
            a mais de 1,5 bilhão de consumidores através da nossa plataforma de
            comércio eletrônico que será lançada em breve,{" "}
            <b>TürkAfrica.Market</b>, sem nenhum custo.
          </p>
          {/* <h4>Lista de Espera de Inscrição Pré-Lançamento</h4> */}
          <Link className="PRE_btn" to="Contact" offset={-100}>
            Registro Gratuito Aqui"
          </Link>

          {/* <div className="store_div">
            <p>Aplicativo Em Breve Disponível em</p>
            <div className="store_img">
              <img src={applelogo} alt="" />
              <img src={playlogo} alt="" />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Section3;
