import React from "react";
import "./sec7.css";
import sec7img from "../../../../assets/sec_7.png";
import applelogo from "../../../../assets/Applestore.png";
import playlogo from "../../../../assets/G_play.png";
const Section7 = () => {
  return (
    <div className="Section7">
      <div className="submain_sec7_div">
        <div className="section7_text_div">
          <h1>
            Receba seu pacote <br /> com facilidade!
          </h1>
          <br />
          <p>
            Aproveite a simplicidade de coletar seu pacote! Uma vez que seu
            pacote chegar ao nosso centro logístico, enviaremos prontamente uma
            mensagem para que você venha buscá-lo. Estamos empenhados em
            garantir que sua experiência de entrega seja o mais fácil e
            conveniente possível.
          </p>
          <div className="store_div">
            <p>Aplicativo em breve disponível em</p>
            <div className="store_img">
              <img src={applelogo} alt="" />
              <img src={playlogo} alt="" />
            </div>
          </div>
        </div>
        <div className="section7_img_div">
          <img src={sec7img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Section7;
