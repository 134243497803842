import { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./index.css";

const Index = () => {
  const [lang, setlang] = useState();
  console.log("🚀 ~ Index ~ lang:", lang);
  const pathname = useLocation();
  const getname = pathname.state.pathname.pathname;
  useEffect(() => {
    setlang(getname);
  }, [pathname]);
  const Navigation = useNavigate();

  return (
    <div className="main_div">
      <div className="arrow">
        <button
          onClick={() =>
            lang == "/Turkish"
              ? Navigation("/Turkish")
              : lang == "/French"
              ? Navigation("/French")
              : lang == "/Portuguese"
              ? Navigation("/Portuguese")
              : lang == "/Swahili"
              ? Navigation("/Swahili")
              : lang == "/Spanish"
              ? Navigation("/Spanish")
              : Navigation("/")
          }
          className="button-27"
          role="button">
          &#8592;
        </button>
      </div>
      <h3 className="head_text">
        {lang == "/Turkish"
          ? "TürkAfrica.Market Kullanım Koşulları"
          : lang == "/French"
          ? "Conditions d'Utilisation de TürkAfrica.Market"
          : lang == "/Portuguese"
          ? "Termos de Uso da TürkAfrica.Market"
          : lang == "/Swahili"
          ? "Vigezo vya Matumizi ya TürkAfrica.Market"
          : lang == "/Spanish"
          ? "Términos de Uso de TürkAfrica.Market"
          : "TürkAfrica.Market Terms of Use"}
      </h3>
      <div className="contacnt_div">
        {lang == "/Turkish" ? (
          <>
            <div className="sub_title_heading">
              <h6>22 Aralık 2023 tarihinde güncellendi</h6>
            </div>
            <div className="text_div">
              <h1>1. Giriş</h1>
              <p>
                TürkAfrica.Market'a hoş geldiniz. Bu Kullanım Koşulları, B2B ve
                B2C ağ oluşturma ve e-ticaret platformumuzu kullanımınızı ve
                erişiminizi yönetir. Hizmetlerimizi kullanarak veya erişerek, bu
                şartları kabul etmiş olursunuz.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Uygunluk </h1>
              <p>
                Platformumuzu kullanmak için: <br />
                En az 18 yaşında olmalısınız. <br />
                Bağlayıcı sözleşmeler yapma yasal kapasitesine sahip
                olmalısınız.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Hesap Kaydı</h1>
              <p>
                Hesap Oluşturma: Kullanıcılar doğru ve eksiksiz bilgi
                sağlamalıdır. Hesap Sorumlulukları: Kullanıcılar, hesaplarının
                ve şifrelerinin gizliliğini korumak ve hesapları altında
                gerçekleşen tüm aktivitelerden sorumlu olmalıdır.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Hizmetlerin Kullanımı</h1>
              <p>
                Uyumluluk: Kullanıcılar, tüm geçerli yasalara, bu sözleşmenin
                şartlarına ve tüm platform politikalarına uymalıdır. <br />
                Yasaklanmış Davranışlar: Kullanıcılar hizmetlerimizi kötüye
                kullanmamalıdır, bu, sahtekarlık faaliyetlerine katılmayı, fikri
                mülkiyeti ihlal etmeyi veya zararlı veya yasa dışı içerik
                yaymayı içerir.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Fikri Mülkiyet</h1>
              <p>
                Sahiplik: TürkAfrica.Market'teki tüm içerikler, metinler,
                grafikler, logolar ve yazılımlar TürkAfrica.Market'in veya
                lisans verenlerinin mülkiyetindedir ve İngiltere ve uluslararası
                fikri mülkiyet yasaları tarafından korunmaktadır. <br />
                İçeriğin Kullanımı: Kullanıcılar, platformumuzdan herhangi bir
                içeriği önceden yazılı izin olmadan kullanamaz.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Üçüncü Taraf Bağlantılar ve Hizmetler</h1>
              <p>
                Dış Bağlantılar: Platformumuz, üçüncü taraf web sitelerine veya
                hizmetlerine bağlantılar içerebilir. Bu sitelerin içeriği,
                gizlilik politikaları veya uygulamalarından sorumlu değiliz.
                <br />
                Üçüncü Taraf Hizmetleri: Platformumuz aracılığıyla üçüncü taraf
                hizmetlerinin kullanımı tamamen kullanıcı ve üçüncü taraf
                arasındadır.
              </p>
            </div>
            <div className="text_div">
              <h1>7. Sonlandırma</h1>
              <p>
                Hesap Sonlandırma: Herhangi bir Kullanım Koşullarını ihlal
                durumunda, önceden haber vermeden veya sorumluluk almadan
                platforma erişimi derhal sonlandırabilir veya askıya alabiliriz.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Feragatnameler ve Sorumluluk Sınırlaması</h1>
              <p>
                Garantiler Yok: Hizmetlerimiz "olduğu gibi" herhangi bir garanti
                olmaksızın sunulmaktadır. Sorumluluk Sınırlaması:
                TürkAfrica.Market, hizmetlerimizin kullanımından kaynaklanan
                dolaylı, tesadüfi, özel, sonuçsal veya cezai zararlardan sorumlu
                olmayacaktır.
              </p>
            </div>
            <div className="text_div">
              <h1>9. Tazminat</h1>
              <p>
                Kullanıcılar, platformumuzun kullanımından kaynaklanan herhangi
                bir iddia, zarar, kayıp, sorumluluk ve masraftan
                TürkAfrica.Market'i, yetkililerini, yöneticilerini,
                çalışanlarını ve acentelerini tazmin etmeyi ve zararsız tutmayı
                kabul eder.
              </p>
            </div>
            <div className="text_div">
              <h1>10. Yönetmelik Hukuku</h1>
              <p>
                Bu Koşullar, Birleşik Krallık yasalarına göre yönetilecek ve
                yorumlanacaktır.
              </p>
            </div>
            <div className="text_div">
              <h1>11.Kullanım Koşullarındaki Değişiklikler</h1>
              <p>
                Bu şartları herhangi bir zamanda değiştirme hakkını saklı
                tutarız. Bu değişiklikler hakkında, platformda revize edilmiş
                şartları yayınlayarak bildirimde bulunacağız.
              </p>
            </div>
            <div className="text_div">
              <h1>12. İletişim Bilgileri</h1>
              <p>
                Bu Kullanım Koşulları ile ilgili herhangi bir sorunuz varsa,
                lütfen açılış sayfasındaki "İletişim" bölümünden bizimle
                iletişime geçin.
              </p>
            </div>
          </>
        ) : lang == "/French" ? (
          <>
            <div className="sub_title_heading">
              <h6>Mis à jour le 22 décembre 2023</h6>
            </div>
            <div className="text_div">
              <h1>1. Introduction</h1>
              <p>
                Bienvenue sur TürkAfrica.Market. Ces Conditions d'Utilisation
                régissent votre accès et utilisation de notre plateforme de
                réseau et de commerce électronique B2B et B2C. En accédant ou en
                utilisant nos services, vous acceptez ces termes.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Admissibilité </h1>
              <p>
                Pour utiliser notre plateforme, vous devez : <br />
                Avoir au moins 18 ans. <br />
                Avoir la capacité juridique de conclure des contrats
                contraignants.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Inscription du Compte</h1>
              <p>
                Création de Compte : Les utilisateurs doivent fournir des
                informations exactes et complètes. <br />
                Responsabilités du Compte : Les utilisateurs sont responsables
                de la confidentialité de leur compte et mot de passe et de
                toutes les activités qui se produisent sous leur compte.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Utilisation des Services</h1>
              <p>
                Conformité : Les utilisateurs doivent se conformer à toutes les
                lois applicables, aux termes de cet accord et à toutes les
                politiques de la plateforme. <br />
                Conduite Interdite : Les utilisateurs ne doivent pas abuser de
                nos services, y compris en se livrant à des activités
                frauduleuses, en portant atteinte à la propriété intellectuelle,
                ou en diffusant du contenu nuisible ou illégal.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Propriété Intellectuelle</h1>
              <p>
                Propriété : Tout le contenu sur TürkAfrica.Market, y compris les
                textes, graphiques, logos et logiciels, est la propriété de
                TürkAfrica.Market ou de ses concédants et est protégé par les
                lois britanniques et internationales sur la propriété
                intellectuelle. <br />
                Utilisation du Contenu : Les utilisateurs ne peuvent pas
                utiliser de contenu de notre plateforme sans autorisation écrite
                préalable.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Liens et Services Tiers</h1>
              <p>
                Liens Externes : Notre plateforme peut contenir des liens vers
                des sites Web ou services tiers. Nous ne sommes pas responsables
                du contenu, des politiques de confidentialité ou des pratiques
                de ces sites.
                <br />
                Services Tiers : Toute utilisation de services tiers via notre
                plateforme se fait uniquement entre l'utilisateur et le tiers.
              </p>
            </div>
            <div className="text_div">
              <h1>7. Résiliation</h1>
              <p>
                • Résiliation du Compte : Nous pouvons résilier ou suspendre
                l'accès à notre plateforme immédiatement, sans préavis ni
                responsabilité, pour toute violation de ces Conditions.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Avertissements et Limitation de Responsabilité</h1>
              <p>
                Aucune Garantie : Nos services sont fournis "tels quels" sans
                aucune garantie, expresse ou implicite. <br />
                Limitation de Responsabilité : TürkAfrica.Market ne sera pas
                responsable des dommages indirects, accessoires, spéciaux,
                consécutifs ou punitifs résultant de l'utilisation de nos
                services.
              </p>
            </div>
            <div className="text_div">
              <h1>9. Indemnisation</h1>
              <p>
                Les utilisateurs acceptent d'indemniser et de dégager de toute
                responsabilité TürkAfrica.Market, ses dirigeants, directeurs,
                employés et agents contre toute réclamation, dommage, perte,
                responsabilité et dépense découlant de leur utilisation de la
                plateforme.
              </p>
            </div>
            <div className="text_div">
              <h1>10. Loi Applicable</h1>
              <p>
                Ces Conditions seront régies et interprétées conformément aux
                lois du Royaume-Uni.
              </p>
            </div>
            <div className="text_div">
              <h1>11. Modifications des Conditions d'Utilisation</h1>
              <p>
                Nous nous réservons le droit de modifier ces termes à tout
                moment. Nous fournirons un avis de ces changements en publiant
                les termes révisés sur la plateforme.
              </p>
            </div>
            <div className="text_div">
              <h1>12. Coordonnées</h1>
              <p>
                Pour toute question concernant ces conditions d'utilisation,
                veuillez nous contacter via la section "Contact" sur la page
                d'accueil.
              </p>
            </div>
          </>
        ) : lang == "/Portuguese" ? (
          <>
            <div className="sub_title_heading">
              <h6>Atualizado em 22 de dezembro de 2023</h6>
            </div>
            <div className="text_div">
              <h1>1. Introdução</h1>
              <p>
                Bem-vindo à TürkAfrica.Market. Estes Termos de Uso regem seu
                acesso e uso de nossa plataforma de networking e comércio
                eletrônico B2B e B2C. Ao acessar ou usar nossos serviços, você
                concorda com estes termos.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Elegibilidade </h1>
              <p>
                Para usar nossa plataforma, você deve: <br />
                Ser maior de 18 anos. <br />
                Ter capacidade legal para celebrar contratos vinculativos.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Registro de Conta</h1>
              <p>
                Criação de Conta: Os usuários devem fornecer informações
                precisas e completas. Responsabilidades da Conta: Os usuários
                são responsáveis por manter a confidencialidade de sua conta e
                senha e por todas as atividades que ocorrem sob sua conta.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Uso dos Serviços</h1>
              <p>
                Compliance: Users must comply with all applicable laws, the
                terms of this agreement, and all platform policies. <br />
                Prohibited Conduct: Users must not misuse our services,
                including engaging in fraudulent activities, infringing on
                intellectual property, or spreading harmful or illegal content.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Intellectual Property</h1>
              <p>
                Conformidade: Os usuários devem cumprir todas as leis
                aplicáveis, os termos deste acordo e todas as políticas da
                plataforma. <br />
                Conduta Proibida: Os usuários não devem fazer uso indevido de
                nossos serviços, incluindo a participação em atividades
                fraudulentas, violação de propriedade intelectual ou
                disseminação de conteúdo prejudicial ou ilegal.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Propriedade Intelectual</h1>
              <p>
                Propriedade: Todo o conteúdo na TürkAfrica.Market, incluindo
                textos, gráficos, logotipos e software, é propriedade da
                TürkAfrica.Market ou seus licenciadores e é protegido pelas leis
                de propriedade intelectual do Reino Unido e internacionais.
                <br />
                Uso do Conteúdo: Os usuários não podem usar nenhum conteúdo de
                nossa plataforma sem permissão prévia por escrito.
              </p>
            </div>
            <div className="text_div">
              <h1>7. ps e Serviços de Terceiros</h1>
              <p>
                ps Externos: Nossa plataforma pode conter ps para sites ou
                serviços de terceiros. Não somos responsáveis pelo conteúdo,
                políticas de privacidade ou práticas desses sites. Serviços de
                Terceiros: Qualquer uso de serviços de terceiros por meio de
                nossa plataforma é exclusivamente entre o usuário e o terceiro.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Rescisão</h1>
              <p>
                Rescisão de Conta: Podemos encerrar ou suspender o acesso à
                nossa plataforma imediatamente, sem aviso prévio ou
                responsabilidade, por qualquer violação destes Termos.
              </p>
            </div>
            <div className="text_div">
              <h1>
                9. Isenções de Responsabilidade e Limitação de Responsabilidade
              </h1>
              <p>
                Sem Garantias: Nossos serviços são fornecidos "como estão" sem
                quaisquer garantias, expressas ou implícitas. Limitação de
                Responsabilidade: A TürkAfrica.Market não será responsável por
                quaisquer danos indiretos, incidentais, especiais,
                consequenciais ou punitivos resultantes do uso de nossos
                serviços.
              </p>
            </div>
            <div className="text_div">
              <h1>10. Indenização</h1>
              <p>
                Os usuários concordam em indenizar e isentar a
                TürkAfrica.Market, seus oficiais, diretores, funcionários e
                agentes de quaisquer reivindicações, danos, perdas,
                responsabilidades e despesas decorrentes de seu uso da
                plataforma.
              </p>
            </div>
            <div className="text_div">
              <h1>11. Lei Aplicável</h1>
              <p>
                Estes Termos serão regidos e interpretados de acordo com as leis
                do Reino Unido.
              </p>
            </div>
            <div className="text_div">
              <h1>12. Alterações nos Termos de Uso</h1>
              <p>
                Reservamo-nos o direito de modificar estes termos a qualquer
                momento. Forneceremos aviso dessas alterações publicando os
                termos revisados na plataforma.
              </p>
            </div>
            <div className="text_div">
              <h1>13. Informações de Contato</h1>
              <p>
                Para quaisquer questões sobre estes Termos de Uso, por favor,
                entre em contato conosco através da seção "Contato" na página de
                destino.
              </p>
            </div>
          </>
        ) : lang == "/Swahili" ? (
          <>
            <div className="sub_title_heading">
              <h6>Imesasishwa tarehe 22 Desemba, 2023</h6>
            </div>
            <div className="text_div">
              <h1>1. Utangulizi</h1>
              <p>
                Karibu kwenye TürkAfrica.Market. Vigezo hivi vya Matumizi
                vinadhibiti ufikiaji wako na matumizi ya jukwaa letu la mtandao
                na biashara kati ya biashara (B2B) na kati ya biashara na wateja
                (B2C). Kwa kufikia au kutumia huduma zetu, unakubaliana na
                vigezo hivi.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Kustahiki </h1>
              <p>
                Ili kutumia jukwaa letu, lazima: <br />
                Uwe na umri wa angalau miaka 18. <br />
                Uwe na uwezo wa kisheria kuingia mikataba inayofunga.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Usajili wa Akaunti</h1>
              <p>
                Uundaji wa Akaunti: Watumiaji lazima watoe taarifa sahihi na
                kamili. Majukumu ya Akaunti: Watumiaji wana jukumu la kudumisha
                usiri wa akaunti na nywila zao na kwa shughuli zote zinazotokea
                chini ya akaunti yao.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Matumizi ya Huduma</h1>
              <p>
                Uzingatiaji: Watumiaji lazima wafuate sheria zote husika, vigezo
                vya makubaliano haya, na sera zote za jukwaa. <br />
                Tabia Zilizokatazwa: Watumiaji hawapaswi kutumia huduma zetu
                vibaya, ikiwa ni pamoja na kujihusisha katika shughuli za
                udanganyifu, kukiuka haki miliki, au kusambaza maudhui yenye
                madhara au yasiyo halali
              </p>
            </div>
            <div className="text_div">
              <h1>5.Mali Miliki</h1>
              <p>
                Umiliki: Maudhui yote kwenye TürkAfrica.Market, ikiwa ni pamoja
                na maandishi, michoro, nembo, na programu, ni mali ya
                TürkAfrica.Market au watoa leseni wake na inalindwa na sheria za
                haki miliki za Uingereza na kimataifa. <br />
                Matumizi ya Maudhui: Watumiaji hawawezi kutumia maudhui yoyote
                kutoka kwenye jukwaa letu bila ruhusa iliyoandikwa mapema.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Viungo na Huduma za Tatu</h1>
              <p>
                Viungo vya Nje: Jukwaa letu linaweza kuwa na viungo kwa tovuti
                au huduma za watu wa tatu. Hatuwajibiki kwa maudhui, sera za
                faragha, au mazoea ya tovuti hizi.
                <br />
                Huduma za Tatu: Matumizi yoyote ya huduma za watu wa tatu
                kupitia jukwaa letu ni kati ya mtumiaji na mtu wa tatu pekee.
              </p>
            </div>
            <div className="text_div">
              <h1>7. Kusitisha</h1>
              <p>
                Kusitishwa kwa Akaunti: Tunaweza kusitisha au kusimamisha
                ufikiaji wa jukwaa letu mara moja, bila taarifa ya awali au
                dhima, kwa ukiukaji wowote wa Vigezo hivi.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Kukana na Ukomo wa Dhima</h1>
              <p>
                Hakuna Dhamana: Huduma zetu zinatolewa "kama zilivyo" bila
                dhamana yoyote, iliyotamkwa au kudokezwa. <br />
                Ukomo wa Dhima: TürkAfrica.Market haitawajibika kwa uharibifu
                wowote wa moja kwa moja, wa bahati mbaya, maalum, wa matokeo, au
                wa adhabu unaotokana na matumizi ya huduma zetu.
              </p>
            </div>
            <div className="text_div">
              <h1>9. Kujilinda</h1>
              <p>
                Watumiaji wanakubali kujilinda na kutowajibisha
                TürkAfrica.Market, maafisa wake, wakurugenzi, wafanyakazi, na
                mawakala kutokana na madai yoyote, uharibifu, hasara, madeni, na
                gharama zinazotokana na matumizi yao ya jukwaa.
              </p>
            </div>
            <div className="text_div">
              <h1>10. Sheria Inayotumika</h1>
              <p>
                Vigezo hivi vitatawaliwa na kutafsiriwa kulingana na sheria za
                Uingereza.
              </p>
            </div>
            <div className="text_div">
              <h1>11. Mabadiliko ya Vigezo vya Matumizi</h1>
              <p>
                Tunahifadhi haki ya kurekebisha vigezo hivi wakati wowote.
                Tutatoa taarifa ya mabadiliko haya kwa kuchapisha vigezo
                vilivyorekebishwa kwenye jukwaa.
              </p>
            </div>
            <div className="text_div">
              <h1>12. Taarifa za Mawasiliano</h1>
              <p>
                Kwa maswali yoyote kuhusu Masharti ya Matumizi, tafadhali
                wasiliana nasi kupitia sehemu ya "Mawasiliano" kwenye ukurasa wa
                kutua.
              </p>
            </div>
          </>
        ) : lang == "/Spanish" ? (
          <>
            <div className="sub_title_heading">
              <h6>Actualizado el 22 de diciembre de 2023</h6>
            </div>
            <div className="text_div">
              <h1>1. Introducción</h1>
              <p>
                Bienvenido a TürkAfrica.Market. Estos Términos de Uso gobiernan
                su acceso y uso de nuestra plataforma de comercio electrónico y
                redes B2B y B2C. Al acceder o utilizar nuestros servicios, usted
                acepta estos términos.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Elegibilidad </h1>
              <p>
                Para usar nuestra plataforma, debe:
                <br />
                Ser mayor de 18 años. <br />
                Tener la capacidad legal para celebrar contratos vinculantes.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Registro de Cuenta</h1>
              <p>
                Creación de Cuenta: Los usuarios deben proporcionar información
                precisa y completa. Responsabilidades de la Cuenta: Los usuarios
                son responsables de mantener la confidencialidad de su cuenta y
                contraseña y de todas las actividades que ocurran bajo su
                cuenta.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Uso de los Servicios</h1>
              <p>
                Cumplimiento: Los usuarios deben cumplir con todas las leyes
                aplicables, los términos de este acuerdo y todas las políticas
                de la plataforma. <br />
                Conducta Prohibida: Los usuarios no deben hacer un mal uso de
                nuestros servicios, incluyendo participar en actividades
                fraudulentas, infringir la propiedad intelectual, o difundir
                contenido dañino o ilegal.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Propiedad Intelectual</h1>
              <p>
                Propiedad: Todo el contenido en TürkAfrica.Market, incluyendo
                textos, gráficos, logotipos y software, es propiedad de
                TürkAfrica.Market o sus licenciantes y está protegido por las
                leyes de propiedad intelectual del Reino Unido e
                internacionales. <br />
                Uso del Contenido: Los usuarios no pueden usar ningún contenido
                de nuestra plataforma sin permiso previo por escrito.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Enlaces y Servicios de Terceros</h1>
              <p>
                Enlaces Externos: Nuestra plataforma puede contener enlaces a
                sitios web o servicios de terceros. No somos responsables del
                contenido, políticas de privacidad, o prácticas de estos sitios.
                <br />
                Servicios de Terceros: Cualquier uso de servicios de terceros a
                través de nuestra plataforma es exclusivamente entre el usuario
                y el tercero.
              </p>
            </div>
            <div className="text_div">
              <h1>7. Terminación</h1>
              <p>
                Terminación de la Cuenta: Podemos terminar o suspender el acceso
                a nuestra plataforma de inmediato, sin previo aviso ni
                responsabilidad, por cualquier incumplimiento de estos Términos.
              </p>
            </div>
            <div className="text_div">
              <h1>
                8. Descargos de Responsabilidad y Limitación de Responsabilidad
              </h1>
              <p>
                Sin Garantías: Nuestros servicios se proporcionan "tal cual" sin
                ninguna garantía, expresa o implícita. Limitación de
                Responsabilidad: TürkAfrica.Market no será responsable de ningún
                daño indirecto, incidental, especial, consecuente o punitivo
                resultante del uso de nuestros servicios.
              </p>
            </div>
            <div className="text_div">
              <h1>9. Indemnización</h1>
              <p>
                Los usuarios aceptan indemnizar y eximir de responsabilidad a
                TürkAfrica.Market, sus oficiales, directores, empleados y
                agentes de cualquier reclamo, daños, pérdidas, responsabilidades
                y gastos que surjan de su uso de la plataforma.
              </p>
            </div>
            <div className="text_div">
              <h1>10. Ley Aplicable</h1>
              <p>
                Estos Términos se regirán e interpretarán de acuerdo con las
                leyes del Reino Unido.
              </p>
            </div>
            <div className="text_div">
              <h1>11. Cambios en los Términos de Uso</h1>
              <p>
                Nos reservamos el derecho de modificar estos términos en
                cualquier momento. Proporcionaremos aviso de estos cambios
                publicando los términos revisados en la plataforma.
              </p>
            </div>
            <div className="text_div">
              <h1>12. Información de Contacto</h1>
              <p>
                Para cualquier pregunta relacionada con estos Términos de Uso,
                por favor contáctenos a través de la sección "Contacto" en la
                página de inicio.
              </p>
            </div>
          </>
        ) : (
          <>
            <div className="sub_title_heading">
              <h6>Updated on December 22, 2023</h6>
            </div>
            <div className="text_div">
              <h1>1. Introduction</h1>
              <p>
                Welcome to TürkAfrica.Market. These Terms of Use govern your
                access to and use of our B2B and B2C networking and e-commerce
                platform. By accessing or using our services, you agree to these
                terms.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Eligibility </h1>
              <p>
                To use our platform, you must: <br />
                Be at least 18 years old. <br />
                Have the legal capacity to enter into binding contracts.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Account Registration</h1>
              <p>
                Account Creation: Users must provide accurate and complete
                information. Account Responsibilities: Users are responsible for
                maintaining the confidentiality of their account and password
                and for all activities that occur under their account.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Use of Services</h1>
              <p>
                Compliance: Users must comply with all applicable laws, the
                terms of this agreement, and all platform policies. <br />
                Prohibited Conduct: Users must not misuse our services,
                including engaging in fraudulent activities, infringing on
                intellectual property, or spreading harmful or illegal content.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Intellectual Property</h1>
              <p>
                Ownership: All content on TürkAfrica.Market, including text,
                graphics, logos, and software, is the property of
                TürkAfrica.Market or its licensors and is protected by UK and
                international intellectual property laws. <br />
                Use of Content: Users may not use any content from our platform
                without prior written permission.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Third-Party ps and Services</h1>
              <p>
                External ps: Our platform may contain ps to third-party websites
                or services. We are not responsible for the content, privacy
                policies, or practices of these sites.
                <br />
                Third-Party Services: Any use of third-party services through
                our platform is solely between the user and the third party.
              </p>
            </div>
            <div className="text_div">
              <h1>7. Termination</h1>
              <p>
                Account Termination: We may terminate or suspend access to our
                platform immediately, without prior notice or liability, for any
                breach of these Terms.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Disclaimers and Limitation of Liability</h1>
              <p>
                No Warranties: Our services are provided "as is" without any
                warranties, expressed or implied. Limitation of Liability:
                TürkAfrica.Market will not be liable for any indirect,
                incidental, special, consequential, or punitive damages
                resulting from the use of our services.
              </p>
            </div>
            <div className="text_div">
              <h1>9. Indemnification</h1>
              <p>
                Users agree to indemnify and hold harmless TürkAfrica.Market,
                its officers, directors, employees, and agents from any claims,
                damages, losses, liabilities, and expenses arising from their
                use of the platform.
              </p>
            </div>
            <div className="text_div">
              <h1>10. Governing Law</h1>
              <p>
                These Terms shall be governed by and construed in accordance
                with the laws of the United Kingdom.
              </p>
            </div>
            <div className="text_div">
              <h1>11. Changes to Terms of Use</h1>
              <p>
                We reserve the right to modify these terms at any time. We will
                provide notice of these changes by posting the revised terms on
                the platform.
              </p>
            </div>
            <div className="text_div">
              <h1>12. Contact Information</h1>
              <p>
                For any questions regarding these Terms of Use, please contact
                us through the 'Contact' section on the landing page.
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Index;
