import React from "react";
import "./sec5.css";
import sec5img from "../../../../assets/sec_5.png";

const Section5 = () => {
  return (
    <div className="section2" id="Sec5">
      <div className="submain_sec5">
        <h1>Nossa Missão</h1>
        <div className="submain_div_sec2">
          <div className="sec2_textbox">
            <p>
              Nossa missão é fortalecer e expandir os laços comerciais e
              econômicos entre a Turquia e o continente africano. Para atingir
              esse objetivo, estabelecemos o <span>TurkAfrica.Market</span> como
              uma plataforma de destaque, dedicada a promover fortes relações
              business-to-consumer (B2C). No centro de nossa visão está o
              objetivo de conectar empresas turcas e africanas através de um
              mercado de comércio eletrônico inovador, permitindo interações
              contínuas entre empresas e consumidores dessas regiões que estão
              crescendo rapidamente.
            </p>

            <p>
              Estamos comprometidos em desbloquear novas oportunidades de
              crescimento, promover parcerias colaborativas e criar valor mútuo.
              Nossa plataforma de ponta foi cuidadosamente projetada para
              atender às necessidades em constante evolução dos mercados turcos
              e africanos, permitindo que as empresas prosperem nesta economia
              global dinâmica e cada vez mais interconectada.
            </p>
          </div>
          <div className="sec5_img_box">
            <img src={sec5img} alt="Not found" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section5;
