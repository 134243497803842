import React from "react";
import "./sec2.css";
import sec2img from "../../../../assets/sec_2.png";

const Section2 = () => {
  return (
    <div className="section2" id="Sec2">
      <div className="submain_sec2">
        <h1>Kuhusu Sisi</h1>

        <div className="submain_div_sec2">
          <div className="sec2_textbox">
            <p>
              Karibu kwenye <span>TurkAfrica.Market</span>, jukwaa la hali ya
              juu la biashara ya mtandaoni lililoundwa ili kuwezesha biashara
              kati ya makampuni ya Kituruki na Kiafrika. Tunajenga daraja kati
              ya kanda hizi mbili zenye nguvu, tukileta soko la B2C
              linalowawezesha wajasiriamali na makampuni kuonyesha na kuuza
              bidhaa zao kuvuka mipaka.
            </p>

            <p>
              Kwa <span>biashara na wajasiriamali wa Kituruki</span>,
              TurkAfrica.Market inatoa fursa ya kufikia soko la Afrika linalokua
              haraka. Jukwaa letu linawaruhusu makampuni ya Kituruki kuanzisha
              bidhaa na huduma zao zenye ubora wa hali ya juu na bei nafuu -
              kuanzia nguo, vifaa vya kielektroniki, vifaa vya nyumbani hadi
              vifaa vya ujenzi na bidhaa za afya - moja kwa moja kwa wateja wa
              Kiafrika. Kwa kuondoa vikwazo vya jadi, biashara za Kituruki
              zinaweza kuanzisha uhusiano wa muda mrefu wa kibiashara, kuongeza
              ufahamu wa chapa, na kutoa suluhisho za kisasa ili kukidhi
              mahitaji ya darasa la kati linaloongezeka la Afrika.
            </p>
          </div>
          <div className="sec2_img_box">
            <img src={sec2img} alt="Not found" />
          </div>
        </div>
      </div>

      <div className="Sec2_bottom_text">
        <p>
          Kwa upande mwingine, <span>biashara za Kiafrika</span> zinapata faida
          ya kufikia moja kwa moja wanunuzi wa Kituruki na wa kimataifa kupitia
          TurkAfrica.Market. Wazalishaji wa kahawa, kakao, viungo, nguo, sanaa
          na zaidi wanaweza kuonyesha bidhaa zao halisi kwa hadhira pana,
          wakiongeza faida kwa kuepuka madalali. Jukwaa letu linahimiza kufikia
          soko pana zaidi, kuhamasisha wajasiriamali wa Kiafrika kukuza biashara
          zao na kushindana kimataifa, huku wakiunda uhusiano wa kibiashara wa
          kudumu na biashara za Kituruki.
        </p>
        <p>
          Katika <span>TurkAfrica.Market</span>, tumejitoa kuimarisha uhusiano
          wa kibiashara wa faida kati ya Uturuki na Afrika, tukileta fursa mpya
          za ukuaji, ushirikiano, na mafanikio kwa biashara na watumiaji.
        </p>
      </div>
    </div>
  );
};

export default Section2;
