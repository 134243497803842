import React from "react";
import "./sec7.css";
import sec7img from "../../../../assets/sec_7.png";
import applelogo from "../../../../assets/Applestore.png";
import playlogo from "../../../../assets/G_play.png";
const Section7 = () => {
  return (
    <div className="Section7">
      <div className="submain_sec7_div">
        <div className="section7_text_div">
          <h1>
            Simply receive your <br /> parcel with ease!
          </h1>
          <p>
            Enjoy the simplicity of collecting your parcel! Once your package
            arrives at our logistic centre, we'll promptly send you a message to
            come and pick it up. We are dedicated to ensuring your delivery
            experience is as effortless and convenient as possible.
          </p>
          <div className="store_div">
            <p>App Coming Soon on</p>
            <div className="store_img">
              <img src={applelogo} alt="" />
              <img src={playlogo} alt="" />
            </div>
          </div>
        </div>
        <div className="section7_img_div">
          <img src={sec7img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Section7;
