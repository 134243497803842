import React from "react";
import "./sec3.css";
import img1 from "../../../../assets/sec_3_1.png";
import img2 from "../../../../assets/sec_3_2.png";
import img3 from "../../../../assets/sec_3_3.png";
import img4 from "../../../../assets/sec_3_4.png";
import img5 from "../../../../assets/sec_3_5.png";
import img6 from "../../../../assets/sec_3_6.png";
import img7 from "../../../../assets/sec_3_7.png";
import img8 from "../../../../assets/sec_3_8.png";
import img9 from "../../../../assets/sec_3_9.png";
import img10 from "../../../../assets/sec_3_10.png";
import img11 from "../../../../assets/sec_3_11.png";
import img12 from "../../../../assets/sec_3_12.png";
import applelogo from "../../../../assets/Applestore.png";
import playlogo from "../../../../assets/G_play.png";
import { Link } from "react-scroll";

const Section3 = () => {
  const data = [
    { id: 1, img: img7, name: "Vipuri vya Magari" },
    { id: 2, img: img3, name: "Mwekezaji" },
    { id: 3, img: img4, name: "Mzalishaji wa Kahawa" },
    { id: 4, img: img9, name: "Mingiza wa Kahawa" },
    { id: 5, img: img8, name: "Muuzaji wa Rejareja" },
    { id: 6, img: img11, name: "Kliniki ya Urembo" },
    { id: 7, img: img12, name: "Biashara ya Kilimo" },
    { id: 8, img: img2, name: "Kampuni ya Uchimbaji Madini" },
    { id: 9, img: img10, name: "Kampuni ya Ubunifu wa Ndani na Nje" },
    { id: 10, img: img6, name: "Muuzaji wa Jumla wa Uzalishaji wa Nguo" },
    { id: 11, img: img5, name: "Wakili wa Biashara/Mwanasheria wa Kampuni" },
    { id: 12, img: img1, name: "Mtengenezaji wa Mapambo na Samani" },
  ];
  return (
    <div className="Section3" id="Sec3">
      <div className="sub_sec3">
        <div className="sec3img_div">
          {data.map((item, i) => {
            return (
              <div className="details_img_box">
                <img src={item.img} alt="" />
                <div className="nametext_div">
                  <h4>{item.name}</h4>
                </div>
              </div>
            );
          })}
        </div>
        <div className="sec3text_div">
          <h1>Pata Ufikiaji wa Premium</h1>
          <p>
            Jenga mtandao, fanya mikataba, na ushirikiane na wasambazaji,
            watengenezaji, wauzaji nje, waagizaji, wawekezaji, watoaji leseni,
            wapokeaji leseni, wauzaji wa jumla, na wauzaji wa rejareja. Aidha,
            unaweza kuuza bidhaa zako au kutoa huduma zako moja kwa moja kwa
            zaidi ya watumiaji bilioni 1.5 kupitia jukwaa letu lijalo la
            biashara ya mtandaoni, TürkAfrica.Market, bila gharama yoyote.
          </p>
          {/* <h4>Orodha ya Kusubiri Usajili Kabla ya Uzinduzi</h4> */}
          <Link className="PRE_btn" to="Contact" offset={-100}>
            Usajili Bure hapa
          </Link>

          {/* <div className="store_div">
            <p>App Itakuja Hivi Karibuni kwenye</p>
            <div className="store_img">
              <img src={applelogo} alt="" />
              <img src={playlogo} alt="" />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Section3;
