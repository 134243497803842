import { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Index = () => {
  const [lang, setlang] = useState();
  console.log("🚀 ~ Index ~ lang:", lang);
  const pathname = useLocation();
  const getname = pathname.state.pathname.pathname;
  useEffect(() => {
    setlang(getname);
  }, [pathname]);
  const Navigation = useNavigate();
  return (
    <div className="main_div">
      <div className="arrow">
        <button
          onClick={() =>
            lang == "/Turkish"
              ? Navigation("/Turkish")
              : lang == "/French"
              ? Navigation("/French")
              : lang == "/Portuguese"
              ? Navigation("/Portuguese")
              : lang == "/Swahili"
              ? Navigation("/Swahili")
              : lang == "/Spanish"
              ? Navigation("/Spanish")
              : Navigation("/")
          }
          className="button-27"
          role="button">
          &#8592;
        </button>
      </div>
      <h3 className={lang == "/French" ? "head_text_french" : "head_text"}>
        {lang == "/Turkish"
          ? "TürkAfrica.Market Gizlilik Politikası"
          : lang == "/French"
          ? "Politique de confidentialité et politique de cookies de TürkAfrica.Market"
          : lang == "/Portuguese"
          ? "Türkafrica.Market Política de privacidade e cookies "
          : lang == "/Swahili"
          ? "Sera ya Faragha ya TürkAfrica.Market"
          : lang == "/Spanish"
          ? "Política de privacidad y cookies de TürkAfrica.Market"
          : "TürkAfrica.Market Privacy Policy & Cookies"}
      </h3>
      <div className="contacnt_div">
        {lang == "/Turkish" ? (
          <>
            <div className="sub_title_heading">
              <h6>Türkafrica.market Gizlilik Politikası</h6>
              <h6>22 Aralık 2023 tarihinde güncellendi</h6>
            </div>
            <div className="text_div">
              <h1>1. Giriş</h1>
              <p>
                TürkAfrica.Market'a hoş geldiniz, güçlü iş bağlantıları
                geliştirmeye ve kapsamlı e-ticaret çözümleri sunmaya adanmış
                önde gelen bir B2B ve B2C platformu. Bu Gizlilik Politikası,
                kişisel bilgilerinizi nasıl topladığımızı, kullandığımızı,
                koruduğumuzu ve paylaştığımızı açıklar.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Bilgi Toplama</h1>
              <p>
                Platformumuzda kayıt olduğunuzda, sipariş verdiğinizde veya
                hizmetlerimizle etkileşimde bulunduğunuzda verdiğiniz kişisel
                bilgileri toplarız. Bu şunları içerir: <br />
                Kişisel Tanımlama Bilgileri: İsim, e-posta adresi, telefon
                numarası, iş detayları. <br />• İşlemsel Bilgi: Platformumuz
                aracılığıyla gerçekleştirdiğiniz işlemlerin ve siparişlerin
                detayları. <br />
                Kullanım Verileri: Web sitemizi ve hizmetlerimizi nasıl
                kullandığınıza dair bilgiler.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Veri Kullanımı</h1>
              <p>
                Verileriniz şunlar için kullanılır: <br />
                Hizmetleri Sağlamak: İşlemleri işlemek ve müşteri desteği
                sağlamak.
                <br />
                Platformumuzu Geliştirmek: Kullanıcı deneyimini geliştirmek ve
                yeni özellikler geliştirmek. <br />
                Pazarlama: Sadece onayınızla tanıtım mesajları ve teklifler
                göndermek.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Veri Paylaşımı ve Açıklama</h1>
              <p>
                Bilgilerinizi şunlarla paylaşabiliriz:
                <br />
                Hizmet Sağlayıcılar: İş operasyonlarında yardımcı olan ortaklar
                (örn. ödeme işleme, lojistik). <br />
                Yasal Yükümlülükler: Yasalar gerektirdiğinde veya haklarımızı
                korumak için.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Veri Güvenliği</h1>
              <p>
                Verilerinizi yetkisiz erişim ve ihlallerden korumak için
                şifreleme ve erişim kontrolleri gibi sağlam güvenlik önlemleri
                uyguluyoruz.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Veri Saklama</h1>
              <p>
                Hizmetlerimizi sağlamak veya yasalar gerektirdiği sürece kişisel
                verilerinizi saklarız, ardından güvenli bir şekilde silinir veya
                anonimleştirilir.
              </p>
            </div>
            <div className="text_div">
              <h1>7. Kullanıcı Hakları</h1>
              <p>
                UK GDPR altında, kişisel verilerinize erişme, düzeltme, silme
                veya kullanımını kısıtlama hakkına sahipsiniz. Bu haklarınızı
                kullanmak için bize başvurabilirsiniz.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Uluslararası Veri Transferleri</h1>
              <p>
                Verileri İngiltere dışına aktardığımızda, yeterli korumayı
                sağladığımızı ve yasal çerçevelere uyduğumuzu garanti ederiz.
              </p>
            </div>
            <div className="text_div">
              <h1>9. İletişim Bilgileri</h1>
              <p>
                Gizlilikle ilgili her türlü sorunuz için, lütfen açılış
                sayfasındaki 'İletişim' bölümünden bize ulaşın.
              </p>
            </div>
            <div className="text_div">
              <h1>10. Politika Güncellemeleri</h1>
              <p>
                Bu politikayı güncelleyebilir ve önemli değişiklikleri
                platformumuz üzerinden bildirebiliriz.
              </p>
            </div>
            <div className="sub_title_heading">
              <h6>TürkAfrica.Market Çerez Politikası</h6>
              <h6>Updated on December 22, 2023</h6>
            </div>
            <div className="text_div">
              <h1>1. Giriş </h1>
              <p>
                Çerezler, kullanıcı deneyimini geliştirmek için kullanılan küçük
                metin dosyalarıdır. Çerez Politikamız, platformumuzda
                kullanımlarını açıklar.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Kullanılan Çerez Türleri </h1>
              <p>
                Temel Çerezler: Platformun işlevselliği için gerekli. <br />
                Analitik Çerezler: Hizmetlerimizi geliştirmek için kullanım
                verilerini toplar. <br />
                Pazarlama Çerezleri: Reklamları kişiselleştirmek için
                kullanılır.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Çerezleri Yönetme </h1>
              <p>
                Çerez tercihlerinizi tarayıcı ayarlarınızdan yönetebilirsiniz.{" "}
                <br />
                Zorunlu olmayan çerezler için izin, platformumuzu ilk
                ziyaretinizde alınır.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Çerez Politikası Değişiklikleri</h1>
              <p>
                Bu politikayı güncelleyebilir ve platformumuzda bildirimde
                bulunabiliriz.
              </p>
            </div>
            <div className="text_div">
              <h1>5. İletişim Bilgileri</h1>
              <p>
                Çerez Politikamız hakkında herhangi bir sorunuz varsa, lütfen
                açılış sayfasındaki 'İletişim' bölümünden bize ulaşın.
              </p>
            </div>
          </>
        ) : lang == "/French" ? (
          <>
            <div className="sub_title_heading">
              <h6>Politique de Confidentialité de TürkAfrica.Market</h6>
              <h6>Mis à jour le 22 décembre 2023</h6>
            </div>
            <div className="text_div">
              <h1>1. Introduction</h1>
              <p>
                Bienvenue sur TürkAfrica.Market, une plateforme B2B et B2C de
                premier plan dédiée à renforcer les connexions commerciales et à
                fournir des solutions complètes de commerce électronique. Cette
                Politique de Confidentialité décrit comment nous collectons,
                utilisons, protégeons et partageons vos informations
                personnelles.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Collecte d'Informations </h1>
              <p>
                Nous collectons des informations personnelles que vous
                fournissez lors de votre inscription sur notre plateforme, de
                vos commandes ou de vos interactions avec nos services. Cela
                inclut : <br />
                Informations Transactionnelles : Détails des transactions et
                commandes effectuées via notre plateforme.
                <br />
                Données d'Utilisation : Informations sur la façon dont vous
                utilisez notre site Web et nos services.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Utilisation des Données</h1>
              <p>
                Vos données sont utilisées pour : <br />
                Fournir des Services : Traiter les transactions et fournir un
                support client. <br />
                Améliorer Notre Plateforme : Améliorer l'expérience utilisateur
                et développer de nouvelles fonctionnalités. <br />
                Marketing : Envoyer des messages promotionnels et des offres,
                uniquement avec votre consentement.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Partage et Divulgation des Données</h1>
              <p>
                Nous pouvons partager vos informations avec : <br />
                Prestataires de Services : Partenaires qui assistent dans les
                opérations commerciales (par exemple, traitement des paiements,
                logistique). <br />
                Obligations Légales : Lorsque requis par la loi ou pour protéger
                nos droits.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Sécurité des Données</h1>
              <p>
                Nous mettons en œuvre des mesures de sécurité robustes telles
                que le cryptage et les contrôles d'accès pour protéger vos
                données contre les accès non autorisés et les violations.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Conservation des Données</h1>
              <p>
                Nous conservons vos données personnelles aussi longtemps que
                nécessaire pour fournir nos services ou tel que requis par la
                loi, après quoi elles sont supprimées de manière sécurisée ou
                anonymisées.
              </p>
            </div>
            <div className="text_div">
              <h1>7. Droits de l'Utilisateur</h1>
              <p>
                Sous le RGPD du Royaume-Uni, vous avez le droit d'accéder, de
                corriger, de supprimer ou de restreindre l'utilisation de vos
                données personnelles. Vous pouvez exercer ces droits en nous
                contactant.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Transferts de Données Internationaux</h1>
              <p>
                Si nous transférons des données hors du Royaume-Uni, nous
                assurons une protection adéquate et le respect des cadres
                juridiques.
              </p>
            </div>
            <div className="text_div">
              <h1>9. Informations de Contact</h1>
              <p>
                Pour toute demande liée à la vie privée, veuillez nous contacter
                via la section "Contact" sur la page d'accueil.
              </p>
            </div>
            <div className="text_div">
              <h1>10. Mises à Jour de la Politique</h1>
              <p>
                Nous pouvons mettre à jour cette politique et vous en
                informerons de tout changement significatif via notre
                plateforme.
              </p>
            </div>
            <div className="sub_title_heading">
              <h6>Politique de Cookies de TürkAfrica.Market</h6>
              <h6>Mis à jour le 22 décembre 2023</h6>
            </div>
            <div className="text_div">
              <h1>1. Introduction </h1>
              <p>
                Les cookies sont de petits fichiers texte utilisés pour
                améliorer l'expérience utilisateur. Notre Politique de Cookies
                explique leur utilisation sur notre plateforme.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Types de Cookies Utilisés </h1>
              <p>
                Cookies Essentiels : Nécessaires pour la fonctionnalité de la
                plateforme. <br />
                Cookies Analytiques : Collectent des données d'utilisation pour
                améliorer nos services.
                <br />
                Cookies Marketing : Utilisés pour personnaliser la publicité.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Gestion des Cookies </h1>
              <p>
                Vous pouvez gérer vos préférences de cookies via les paramètres
                de votre navigateur. <br />
                Le consentement pour les cookies non essentiels est obtenu lors
                de votre première visite sur notre plateforme.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Modifications de la Politique de Cookies </h1>
              <p>
                Nous pouvons mettre à jour cette politique et fournirons un avis
                sur notre plateforme.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Informations de Contact </h1>
              <p>
                Pour toute question concernant notre Politique de Cookies,
                veuillez nous contacter via la section "Contact" sur la page
                d'accueil.
              </p>
            </div>
          </>
        ) : lang == "/Portuguese" ? (
          <>
            <div className="sub_title_heading">
              <h6>Política de Privacidade da TürkAfrica.Market</h6>
              <h6>Atualizado em 22 de dezembro de 2023.</h6>
            </div>
            <div className="text_div">
              <h1>1. Introdução</h1>
              <p>
                Bem-vindo à TürkAfrica.Market, uma plataforma líder B2B e B2C
                dedicada a promover fortes conexões comerciais e fornecer
                soluções abrangentes de comércio eletrônico. Esta Política de
                Privacidade descreve como coletamos, usamos, protegemos e
                compartilhamos suas informações pessoais.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Coleta de Informações</h1>
              <p>
                Coletamos informações pessoais que você fornece ao se registrar
                em nossa plataforma, fazer pedidos ou interagir com nossos
                serviços. Isso inclui: <br />
                Informações de Identificação Pessoal: Nome, endereço de e-mail,
                número de telefone, detalhes comerciais. Informações
                Transacionais: Detalhes das transações e pedidos realizados
                através de nossa plataforma.
                <br />
                Dados de Uso: Informações sobre como você usa nosso site e
                serviços.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Uso de Dados</h1>
              <p>
                Seus dados são usados para: <br />
                Cumprir Serviços: Processar transações e fornecer suporte ao
                cliente. <br />
                Melhorar Nossa Plataforma: Aprimorar a experiência do usuário e
                desenvolver novos recursos. <br />
                Marketing: Enviar mensagens promocionais e ofertas, somente com
                o seu consentimento.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Compartilhamento e Divulgação de Dados</h1>
              <p>
                Podemos compartilhar suas informações com: <br />
                Prestadores de Serviços: Parceiros que auxiliam nas operações
                comerciais (por exemplo, processamento de pagamentos,
                logística). <br />
                Obrigações Legais: Quando exigido por lei ou para proteger
                nossos direitos.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Segurança de Dados</h1>
              <p>
                Implementamos medidas de segurança robustas, como criptografia e
                controles de acesso, para proteger seus dados de acessos não
                autorizados e violações.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Retenção de Dados</h1>
              <p>
                Retemos seus dados pessoais pelo tempo necessário para fornecer
                nossos serviços ou conforme exigido por lei, após o qual são
                excluídos ou anonimizados de forma segura.
              </p>
            </div>
            <div className="text_div">
              <h1>7. Direitos do Usuário</h1>
              <p>
                Sob o GDPR do Reino Unido, você tem o direito de acessar,
                corrigir, excluir ou restringir o uso de seus dados pessoais.
                Você pode exercer esses direitos entrando em contato conosco.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Transferências Internacionais de Dados</h1>
              <p>
                Se transferirmos dados para fora do Reino Unido, garantimos
                proteção adequada e conformidade com frameworks legais.
              </p>
            </div>
            <div className="text_div">
              <h1>9.Informações de Contato</h1>
              <p>
                Para quaisquer perguntas relacionadas à privacidade, por favor,
                entre em contato conosco através da seção "Contato" na página de
                destino.
              </p>
            </div>
            <div className="text_div">
              <h1>10. Atualizações da Política</h1>
              <p>
                Podemos atualizar esta política e notificaremos você sobre
                mudanças significativas através de nossa plataforma.
              </p>
            </div>
            <div className="sub_title_heading">
              <h6>Política de Cookies da TürkAfrica.Market</h6>
              <h6>Atualizado em 22 de dezembro de 2023.</h6>
            </div>
            <div className="text_div">
              <h1>1. Introdução </h1>
              <p>
                Cookies são pequenos arquivos de texto usados para aprimorar a
                experiência do usuário. Nossa Política de Cookies explica o uso
                deles em nossa plataforma.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Tipos de Cookies Usados </h1>
              <p>
                Cookies Essenciais: Necessários para a funcionalidade da
                plataforma. <br />
                Cookies de Análise: Coletam dados de uso para melhorar nossos
                serviços. <br />
                Cookies de Marketing: Usados para personalizar a publicidade.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Gerenciamento de Cookies </h1>
              <p>
                Você pode gerenciar suas preferências de cookies através das
                configurações do seu navegador. <br />O consentimento para
                cookies não essenciais é obtido em sua primeira visita à nossa
                plataforma.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Alterações na Política de Cookies</h1>
              <p>
                Podemos atualizar esta política e forneceremos aviso em nossa
                plataforma.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Informações de Contato </h1>
              <p>
                Para quaisquer dúvidas sobre nossa Política de Cookies, por
                favor, entre em contato conosco através da seção "Contato" na
                página de destino.
              </p>
            </div>
          </>
        ) : lang == "/Swahili" ? (
          <>
            <div className="sub_title_heading">
              <h6>TürkAfrica.Market Privacy Policy </h6>
              <h6>Imesasishwa tarehe 22 Desemba, 2023</h6>
            </div>
            <div className="text_div">
              <h1>1. Utangulizi</h1>
              <p>
                Karibu kwenye TürkAfrica.Market, jukwaa la kipekee la B2B na B2C
                lililojitolea kuimarisha uhusiano wa kibiashara na kutoa
                suluhisho kamili la biashara ya mtandao. Sera hii ya Faragha
                inaelezea jinsi tunavyokusanya, kutumia, kulinda, na kushiriki
                taarifa yako binafsi.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Ukusanyaji wa Taarifa</h1>
              <p>
                Tunakusanya taarifa binafsi unazotoa unapojisajili kwenye jukwaa
                letu, kuweka maagizo, au kuingiliana na huduma zetu. Hii
                inajumuisha: <br />
                Taarifa za Utambulisho Binafsi: Jina, anwani ya barua pepe,
                nambari ya simu, maelezo ya biashara.Taarifa za Miamala: Maelezo
                ya miamala na maagizo unayofanya kupitia jukwaa letu. <br />
                Data ya Matumizi: Taarifa kuhusu jinsi unavyotumia tovuti yetu
                na huduma
              </p>
            </div>
            <div className="text_div">
              <h1>3. Matumizi ya Data</h1>
              <p>
                Data yako inatumika kwa: <br />
                Kutimiza Huduma: Kuchakata miamala na kutoa usaidizi kwa wateja.{" "}
                <br />
                Kuboresha Jukwaa Letu: Kuimarisha uzoefu wa mtumiaji na kukuza
                vipengele vipya. <br />
                Masoko: Kutuma ujumbe wa kibiashara na ofa, tu kwa idhini yako
              </p>
            </div>
            <div className="text_div">
              <h1>4. Ushirikiano na Ufunuo wa Data</h1>
              <p>
                Tunaweza kushiriki taarifa yako na: <br />
                Watoa Huduma: Washirika wanaosaidia katika operesheni za
                biashara (k.m. uchakataji wa malipo, vifaa vya usafirishaji).{" "}
                <br />
                Majukumu ya Kisheria: Tunapohitajika kisheria au kulinda haki
                zetu.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Usalama wa Data</h1>
              <p>
                Tunatekeleza hatua madhubuti za usalama kama usimbaji fiche na
                udhibiti wa ufikiaji kulinda data yako dhidi ya ufikiaji
                usioruhusiwa na uvunjaji.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Uhifadhi wa Data</h1>
              <p>
                Tunahifadhi data yako binafsi kwa muda mrefu kama inavyohitajika
                kutoa huduma zetu au kama inavyotakiwa na sheria, baada ya hapo
                inafutwa kwa usalama au kufanywa isiyoweza kutambulika.
              </p>
            </div>
            <div className="text_div">
              <h1>7. Haki za Mtumiaji</h1>
              <p>
                Chini ya GDPR ya Uingereza, una haki ya kufikia, kusahihisha,
                kufuta, au kuzuia matumizi ya data yako binafsi. Unaweza
                kutekeleza haki hizi kwa kuwasiliana nasi.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Uhamishaji wa Data wa Kimataifa</h1>
              <p>
                IIkiwa tunahamisha data nje ya Uingereza, tunahakikisha ulinzi
                wa kutosha na kufuata mifumo ya kisheria.
              </p>
            </div>
            <div className="text_div">
              <h1>9. Taarifa za Mawasiliano</h1>
              <p>
                Kwa uchunguzi wowote unaohusiana na faragha, tafadhali wasiliana
                nasi kupitia sehemu ya "Mawasiliano" kwenye ukurasa wa kutua.
              </p>
            </div>
            <div className="text_div">
              <h1>10. Maboresho ya Sera</h1>
              <p>
                Tunaweza kuboresha sera hii na tutakujulisha kuhusu mabadiliko
                makubwa kupitia jukwaa letu.
              </p>
            </div>
            <div className="sub_title_heading">
              <h6>Sera ya Kuki ya TürkAfrica.Market</h6>
              <h6>Imesasishwa tarehe 22 Desemba, 2023</h6>
            </div>
            <div className="text_div">
              <h1>1. Utangulizi </h1>
              <p>
                Kuki ni faili ndogo za maandishi zinazotumika kuboresha uzoefu
                wa mtumiaji. Sera yetu ya Kuki inafafanua matumizi yao kwenye
                jukwaa letu.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Aina za Kuki Zinazotumika </h1>
              <p>
                Kuki Muhimu: Zinahitajika kwa utendakazi wa jukwaa.
                <br />
                Kuki za Takwimu: Zinakusanya data ya matumizi kuboresha huduma
                zetu.
                <br />
                Kuki za Matangazo: Zinatumika kwa ajili ya kubinafsisha
                matangazo.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Usimamizi wa Kuki </h1>
              <p>
                Unaweza kudhibiti mapendeleo yako ya kuki kupitia mipangilio ya
                kivinjari chako. Idhini kwa kuki zisizo muhimu inapatikana
                unapotembelea jukwaa letu kwa mara ya kwanza.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Mabadiliko kwenye Sera ya Kuki</h1>
              <p>
                Tunaweza kuboresha sera hii na tutatoa taarifa kwenye jukwaa
                letu.
              </p>
            </div>
            <div className="text_div">
              <h1>5.Taarifa za Mawasiliano </h1>
              <p>
                Kwa maswali yoyote kuhusu Sera yetu ya Kuki, tafadhali wasiliana
                nasi kupitia sehemu ya "Mawasiliano" kwenye ukurasa wa kutua.
              </p>
            </div>
          </>
        ) : lang == "/Spanish" ? (
          <>
            <div className="sub_title_heading">
              <h6>Política de Privacidad de TürkAfrica.Market</h6>
              <h6>Actualizado el 22 de diciembre de 2023</h6>
            </div>
            <div className="text_div">
              <h1>1. Introducción</h1>
              <p>
                Bienvenido a TürkAfrica.Market, una plataforma líder B2B y B2C
                dedicada a fomentar fuertes conexiones comerciales y
                proporcionar soluciones integrales de comercio electrónico. Esta
                Política de Privacidad describe cómo recopilamos, usamos,
                protegemos y compartimos su información personal.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Recolección de Información</h1>
              <p>
                Recopilamos información personal que usted proporciona al
                registrarse en nuestra plataforma, realizar pedidos o
                interactuar con nuestros servicios. Esto incluye: <br />
                Información de Identificación Personal: Nombre, dirección de
                correo electrónico, número de teléfono, detalles comerciales.
                Información Transaccional: Detalles de las transacciones y
                pedidos que realiza a través de nuestra plataforma. <br />
                Datos de Uso: Información sobre cómo usa nuestro sitio web y
                servicios.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Uso de Datos</h1>
              <p>
                Sus datos se utilizan para:
                <br />
                Cumplir Servicios: Procesar transacciones y proporcionar soporte
                al cliente. <br />
                Mejorar Nuestra Plataforma: Mejorar la experiencia del usuario y
                desarrollar nuevas funciones.
                <br />
                Marketing: Enviar mensajes promocionales y ofertas, solo con su
                consentimiento.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Compartición y Divulgación de Datos</h1>
              <p>
                Podemos compartir su información con: <br />
                Proveedores de Servicios: Socios que ayudan en las operaciones
                comerciales (por ejemplo, procesamiento de pagos, logística).{" "}
                <br />
                Obligaciones Legales: Cuando lo exija la ley o para proteger
                nuestros derechos.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Seguridad de Datos</h1>
              <p>
                Implementamos medidas de seguridad robustas como encriptación y
                controles de acceso para proteger sus datos del acceso no
                autorizado y violaciones.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Retención de Datos</h1>
              <p>
                Retenemos sus datos personales el tiempo que sea necesario para
                proporcionar nuestros servicios o según lo requiera la ley,
                después de lo cual se elimina de manera segura o se anonimiza.
              </p>
            </div>
            <div className="text_div">
              <h1>7. Derechos del Usuario</h1>
              <p>
                Bajo el GDPR del Reino Unido, tiene derecho a acceder, corregir,
                eliminar o restringir el uso de sus datos personales. Puede
                ejercer estos derechos contactándonos.
              </p>
            </div>
            <div className="text_div">
              <h1>8. Transferencias Internacionales de Datos</h1>
              <p>
                Si transferimos datos fuera del Reino Unido, aseguramos una
                protección adecuada y cumplimiento con marcos legales.
              </p>
            </div>
            <div className="text_div">
              <h1>9. Información de Contacto </h1>
              <p>
                Para cualquier consulta relacionada con la privacidad, por favor
                contáctenos a través de la sección "Contacto" en la página de
                aterrizaje.
              </p>
            </div>
            <div className="text_div">
              <h1>10. Actualizaciones de la Política</h1>
              <p>
                Podemos actualizar esta política y le notificaremos sobre
                cambios significativos a través de nuestra plataforma.
              </p>
            </div>
            <div className="sub_title_heading">
              <h6>Política de Cookies de TürkAfrica.Market</h6>
              <h6>Actualizado el 22 de diciembre de 2023</h6>
            </div>
            <div className="text_div">
              <h1>1. Introducción </h1>
              <p>
                Las cookies son pequeños archivos de texto utilizados para
                mejorar la experiencia del usuario. Nuestra Política de Cookies
                explica su uso en nuestra plataforma.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Tipos de Cookies Utilizadas </h1>
              <p>
                Cookies Esenciales: Necesarias para la funcionalidad de la
                plataforma. <br />
                Cookies de Análisis: Recopilan datos de uso para mejorar
                nuestros servicios. <br />
                Cookies de Marketing: Utilizadas para personalizar la
                publicidad.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Gestión de Cookies </h1>
              <p>
                Puede gestionar sus preferencias de cookies a través de la
                configuración de su navegador. <br />
                El consentimiento para cookies no esenciales se obtiene en su
                primera visita a nuestra plataforma.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Cambios en la Política de Cookies</h1>
              <p>
                Podemos actualizar esta política y proporcionaremos aviso en
                nuestra plataforma.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Información de Contacto</h1>
              <p>
                Para cualquier consulta sobre nuestra Política de Cookies, por
                favor contáctenos a través de la sección "Contacto" en la página
                de aterrizaje.
              </p>
            </div>
          </>
        ) : (
          <>
            <div className="sub_title_heading">
              <h6>TürkAfrica.Market Privacy Policy </h6>
              <h6>Updated on December 22, 2023</h6>
            </div>
            <div className="text_div">
              <h1>1. Introduction</h1>
              <p>
                Welcome to TürkAfrica.Market, a leading B2B and B2C platform
                dedicated to fostering strong business connections and providing
                comprehensive e-commerce solutions. This Privacy Policy outlines
                how we collect, use, protect, and share your personal
                information.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Information Collection</h1>
              <p>
                We collect personal information that you provide when you
                register on our platform, place orders, or interact with our
                services. This includes: <br />
                Personal Identification Information: Name, email address, phone
                number, business details. Transactional Information: Details of
                the transactions and orders you carry out through our platform.{" "}
                <br />
                Usage Data: Information on how you use our website and services.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Use of Data</h1>
              <p>
                Your data is used to: Fulfill Services: <br />
                Process transactions and provide customer support. <br />
                Improve Our Platform: Enhance user experience and develop new
                features. <br />
                Marketing: Send promotional messages and offers, only with your
                consent.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Data Sharing and Disclosure</h1>
              <p>
                We may share your information with: <br />
                Service Providers: Partners who assist in business operations
                (e.g., payment processing, logistics). <br />
                Legal Obligations: When required by law or to protect our
                rights.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Data Security</h1>
              <p>
                We implement robust security measures like encryption and access
                controls to protect your data from unauthorized access and
                breaches.
              </p>
            </div>
            <div className="text_div">
              <h1>6. Data Retention</h1>
              <p>
                We retain your personal data as long as necessary to provide our
                services or as required by law, after which it is securely
                deleted or anonymized.
              </p>
            </div>
            <div className="text_div">
              <h1>7. User Rights</h1>
              <p>
                Under the UK GDPR, you have the right to access, correct,
                delete, or restrict the use of your personal data. You can
                exercise these rights by contacting us.
              </p>
            </div>
            <div className="text_div">
              <h1>8. International Data Transfers</h1>
              <p>
                If we transfer data outside the UK, we ensure adequate
                protection and compliance with legal frameworks.
              </p>
            </div>
            <div className="text_div">
              <h1>9. Contact Information</h1>
              <p>
                For any privacy-related inquiries, please contact us through the
                'Contact' section on the landing page
              </p>
            </div>
            <div className="text_div">
              <h1>10. Policy Updates</h1>
              <p>
                We may update this policy and will notify you of significant
                changes through our platform.
              </p>
            </div>
            <div className="sub_title_heading">
              <h6>TürkAfrica.Market Cookies</h6>
              <h6>Updated on December 22, 2023</h6>
            </div>
            <div className="text_div">
              <h1>1. Introduction </h1>
              <p>
                Cookies are small text files used to enhance user experience.
                Our Cookie Policy explains their use on our platform.
              </p>
            </div>
            <div className="text_div">
              <h1>2. Types of Cookies Used </h1>
              <p>
                Essential Cookies: Necessary for the platform's functionality.{" "}
                <br />
                Analytics Cookies: Collect usage data to improve our services.{" "}
                <br />
                Marketing Cookies: Used for personalizing advertising.
              </p>
            </div>
            <div className="text_div">
              <h1>3. Managing Cookies </h1>
              <p>
                You can manage your cookie preferences through your browser
                settings. <br />
                Consent for nonessential cookies is obtained upon your first
                visit to our platform.
              </p>
            </div>
            <div className="text_div">
              <h1>4. Changes to Cookie Policy </h1>
              <p>
                We may update this policy and will provide notice on our
                platform.
              </p>
            </div>
            <div className="text_div">
              <h1>5. Contact Information </h1>
              <p>
                For any queries about our Cookie Policy, please contact us
                through the 'Contact' section on the landing page..
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Index;
