import React from "react";
import "./sec5.css";
import sec5img from "../../../../assets/sec_5.png";

const Section5 = () => {
  return (
    <div className="section2" id="Sec5">
      <div className="submain_sec5">
        <h1>Our Mission</h1>
        <div className="submain_div_sec2">
          <div className="sec2_textbox">
            <p>
              Our mission is to strengthen and expand the commercial and
              economic ties between Türkiye and the African continent. To
              achieve this, we have established <span>TurkAfrica.Market</span>{" "}
              as a premier platform, dedicated to fostering robust
              business-to-consumer (B2C) relationships. At the core of our
              vision is the goal of connecting Turkish and African enterprises
              through an innovative ecommerce marketplace, enabling seamless
              interactions between businesses and consumers across these rapidly
              growing regions.
            </p>

            <p>
              We are committed to unlocking new opportunities for growth,
              fostering collaborative partnerships, and creating mutual value.
              Our cutting-edge platform is meticulously designed to cater to the
              evolving needs of both Turkish and African markets, empowering
              businesses to thrive in this dynamic and increasingly
              interconnected global economy.
            </p>
          </div>
          <div className="sec5_img_box">
            <img src={sec5img} alt="Not found" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section5;
