import React from "react";
import "./sec5.css";
import sec5img from "../../../../assets/sec_5.png";

const Section5 = () => {
  return (
    <div className="section2" id="Sec5">
      <div className="submain_sec5">
        <h1>Nuestra Misión</h1>
        <div className="submain_div_sec2">
          <div className="sec2_textbox">
            <p>
              Nuestra misión es fortalecer y expandir los lazos comerciales y
              económicos entre Turquía y el continente africano. Para lograrlo,
              hemos establecido <span>TurkAfrica.Market</span> como una
              plataforma de primer nivel, dedicada a fomentar relaciones sólidas
              entre empresas y consumidores (B2C). En el corazón de nuestra
              visión está el objetivo de conectar a las empresas turcas y
              africanas a través de un mercado de comercio electrónico
              innovador, que permita interacciones fluidas entre empresas y
              consumidores en estas regiones de rápido crecimiento.
            </p>

            <p>
              Estamos comprometidos a desbloquear nuevas oportunidades de
              crecimiento, fomentar asociaciones colaborativas y crear valor
              mutuo. Nuestra plataforma de vanguardia está meticulosamente
              diseñada para satisfacer las necesidades cambiantes tanto de los
              mercados turcos como africanos, permitiendo a las empresas
              prosperar en esta economía global dinámica e interconectada.
            </p>
          </div>
          <div className="sec5_img_box">
            <img src={sec5img} alt="Not found" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section5;
