import React from "react";
import "./sec7.css";
import sec7img from "../../../../assets/sec_7.png";
import applelogo from "../../../../assets/Applestore.png";
import playlogo from "../../../../assets/G_play.png";
const Section7 = () => {
  return (
    <div className="Section7">
      <div className="submain_sec7_div">
        <div className="section7_text_div">
          <h1>
            Pokea kifurushi chako <br /> kwa urahisi!
          </h1>
          <p>
            Furahia urahisi wa kukusanya kifurushi chako! Mara tu kifurushi
            chako kitakapofika katika kituo chetu cha vifaa, tutakutumia ujumbe
            mara moja uje uchukue. Tumejitolea kuhakikisha kuwa uzoefu wako wa
            utoaji ni rahisi na wa urahisi iwezekanavyo.
          </p>
          <div className="store_div">
            <p>App Inakuja Hivi Karibuni kwenye</p>
            <div className="store_img">
              <img src={applelogo} alt="" />
              <img src={playlogo} alt="" />
            </div>
          </div>
        </div>
        <div className="section7_img_div">
          <img src={sec7img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Section7;
