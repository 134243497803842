import React from "react";
import "./sec2.css";
import sec2img from "../../../../assets/sec_2.png";

const Section2 = () => {
  return (
    <div className="section2" id="Sec2">
      <div className="submain_sec2">
        <h1>Sobre nós</h1>

        <div className="submain_div_sec2">
          <div className="sec2_textbox">
            <p>
              Bem-vindo ao <span>TurkAfrica.Market</span>, uma plataforma
              avançada de comércio eletrônico projetada para facilitar o
              comércio dinâmico entre empresas turcas e africanas. Preenchemos a
              lacuna entre essas duas regiões vibrantes, criando um mercado B2C
              que capacita empreendedores e empresas a mostrar e vender seus
              produtos através das fronteiras.
            </p>

            <p>
              Para as <span>empresas e empreendedores turcos</span>, o
              TurkAfrica.Market oferece uma oportunidade de explorar o mercado
              africano em rápido crescimento. Nossa plataforma permite que as
              empresas turcas apresentem seus produtos e serviços de alta
              qualidade e acessíveis - desde têxteis, eletrônicos e
              eletrodomésticos até materiais de construção e produtos de saúde -
              diretamente aos consumidores africanos. Ao eliminar barreiras
              tradicionais, as empresas turcas podem estabelecer laços
              comerciais de longo prazo, aumentar a notoriedade da marca e
              fornecer soluções personalizadas para atender às necessidades em
              constante evolução da crescente classe média africana.
            </p>
          </div>
          <div className="sec2_img_box">
            <img src={sec2img} alt="Not found" />
          </div>
        </div>
      </div>

      <div className="Sec2_bottom_text">
        <p>
          Por outro lado, as <span>empresas africanas</span> se beneficiam de
          acesso direto aos compradores turcos e internacionais através do
          TurkAfrica.Market. Produtores de café, cacau, especiarias, têxteis,
          artesanato e muito mais podem mostrar seus produtos autênticos a um
          público mais amplo, aumentando suas margens de lucro ao contornar
          intermediários. Nossa plataforma promove um maior alcance de mercado,
          encorajando os empreendedores africanos a expandir seus negócios e
          competir globalmente, enquanto constroem relações comerciais
          sustentáveis com empresas turcas.
        </p>
        <p>
          No <span>TurkAfrica.Market</span>, estamos comprometidos em fomentar
          uma relação comercial recíproca e próspera entre a Turquia e a África,
          criando novas oportunidades de crescimento, colaboração e sucesso para
          empresas e consumidores.
        </p>
      </div>
    </div>
  );
};

export default Section2;
