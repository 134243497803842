import React from "react";
import "./sec2.css";
import sec2img from "../../../../assets/sec_2.png";

const Section2 = () => {
  return (
    <div className="section2" id="Sec2">
      <div className="submain_sec2">
        <h1>À propos de nous</h1>

        <div className="submain_div_sec2">
          <div className="sec2_textbox">
            <p>
              Bienvenue sur <span>TurkAfrica.Market</span>, une plateforme
              avancée de commerce électronique conçue pour faciliter le commerce
              dynamique entre les entreprises turques et africaines. Nous
              comblons le fossé entre ces deux régions dynamiques, créant un
              marché B2C transparent qui permet aux entrepreneurs et aux
              entreprises de présenter et de vendre leurs produits au-delà des
              frontières.
            </p>

            <p>
              Pour les <span>entreprises et entrepreneurs turcs</span>,
              TurkAfrica.Market offre l'opportunité de pénétrer le marché
              africain en pleine expansion. Notre plateforme permet aux
              entreprises turques de présenter des produits et services de haute
              qualité et abordables - allant des textiles, appareils
              électroniques et électroménagers aux matériaux de construction et
              produits de santé - directement aux consommateurs africains. En
              supprimant les barrières traditionnelles, les entreprises turques
              peuvent établir des relations commerciales à long terme, accroître
              la notoriété de leur marque et offrir des solutions adaptées aux
              besoins évolutifs de la classe moyenne africaine en pleine
              croissance.
            </p>
          </div>
          <div className="sec2_img_box">
            <img src={sec2img} alt="Not found" />
          </div>
        </div>
      </div>

      <div className="Sec2_bottom_text">
        <p>
          D'autre part, les <span>entreprises africaines</span> bénéficient d'un
          accès direct aux acheteurs turcs et internationaux via
          TurkAfrica.Market. Les producteurs de café, cacao, épices, textiles,
          artisanat et bien plus encore peuvent présenter leurs produits
          authentiques à un public plus large, augmentant ainsi leurs marges
          bénéficiaires en contournant les intermédiaires. Notre plateforme
          favorise une plus grande portée du marché, encourageant les
          entrepreneurs africains à développer leurs entreprises et à
          concurrencer à l'échelle mondiale, tout en établissant des relations
          commerciales durables avec les entreprises turques.
        </p>
        <p>
          Chez <span>TurkAfrica.Market</span>, nous nous engageons à favoriser
          une relation commerciale réciproque et prospère entre la Turquie et
          l'Afrique, créant de nouvelles opportunités de croissance, de
          collaboration et de succès pour les entreprises et les consommateurs.
        </p>
      </div>
    </div>
  );
};

export default Section2;
