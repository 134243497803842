import React from "react";
import "./sec8.css";
import sec8img from "../../../../assets/sec_8.png";
import applelogo from "../../../../assets/Apple_store.png";
import playlogo from "../../../../assets/G_play.png";

const Section8 = () => {
  return (
    <div className="Section8" id="Sec8">
      <div className="section8_text_div">
        <div className="heading_text">
          <h1>
            Téléchargez l'Application TürkAfrica<span>&#174;</span> Marché
          </h1>
          <p>Produits et Services de Haute Qualité à un Prix Abordable</p>
        </div>
        <div className="Section8_img">
          <img src={sec8img} alt="" />
        </div>
        <div className="store_div">
          <p style={{ color: "black", textAlign: "center" }}>
            Application Bientôt Disponible sur
          </p>
          <div
            className="store_img"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <img src={applelogo} alt="" />
            <img src={playlogo} alt="" />
          </div>
        </div>
        <div className="bottom_text_div">
          <p>
            Découvrez un marché complet où vous pouvez acquérir une vaste gamme
            de produits essentiels pour vos besoins professionnels et
            quotidiens. Interagissez avec une diversité de professionnels
            hautement qualifiés, chacun offrant des services spécialisés adaptés
            à vos exigences. Connectez-vous avec un réseau étendu de plus de 1,5
            milliard de consommateurs et établissez des relations précieuses
            avec un large éventail d'entités commerciales, y compris des
            distributeurs, fabricants, exportateurs, importateurs, grossistes,
            détaillants, concédants, licenciés et investisseurs.{" "}
            <b>TürkAfrica.Market</b>
            se présente comme votre porte d'entrée vers un monde d'opportunités,
            favorisant les connexions et la croissance dans un marché mondial
            dynamique.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section8;
