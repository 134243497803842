import React from "react";
import "./sec1.css";

const Section1 = () => {
  return (
    <div className="section_one" id="Sec1">
      <div className="submain_div">
        <div className="inner_text_div">
          <span>
            Bienvenue sur{" "}
            <h1 style={{ marginLeft: "15px" }}> TürkAfrica.Market</h1>
          </span>

          <h4>Produits et Services de Haute Qualité à un Prix Abordable</h4>
        </div>
      </div>
    </div>
  );
};

export default Section1;
