import React from "react";
import "./sec1.css";

const Section1 = () => {
  return (
    <div className="section_one" id="Sec1">
      <div className="submain_div">
        <div className="inner_text_div">
          <span>
            Bienvenidos a
            <h1 style={{ marginLeft: "15px" }}> TürkAfrica.Market </h1>
          </span>
          {/* <h3>Welcome to</h3>
          <h1>
            TürkAfrica.Market <span>&#174;</span>
          </h1> */}
          <h4>Productos y Servicios de Alta Calidad a Precios Asequibles</h4>
        </div>
      </div>
    </div>
  );
};

export default Section1;
