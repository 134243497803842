import React from "react";
import "./sec8.css";
import sec8img from "../../../../assets/sec_8.png";
import applelogo from "../../../../assets/Apple_store.png";
import playlogo from "../../../../assets/G_play.png";

const Section8 = () => {
  return (
    <div className="Section8" id="Sec8">
      <div className="section8_text_div">
        <div className="heading_text">
          <h1>
            DPakua App ya TürkAfrica<span>&#174;</span> Soko
          </h1>
          <p>Bidhaa na Huduma za Ubora wa Juu kwa Bei Nafuu</p>
        </div>
        <div className="Section8_img">
          <img src={sec8img} alt="" />
        </div>
        <div className="store_div">
          <p style={{ color: "black", textAlign: "center" }}>
            App Inakuja Hivi Karibuni kwenye
          </p>
          <div
            className="store_img"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <img src={applelogo} alt="" />
            <img src={playlogo} alt="" />
          </div>
        </div>
        <div className="bottom_text_div">
          <p>
            Gundua soko kamili ambapo unaweza kupata anuwai kubwa ya bidhaa
            muhimu kwa mahitaji yako ya kikazi na ya kila siku. Shirikiana na
            wataalamu mbalimbali walio na ujuzi wa hali ya juu, kila mmoja akiwa
            na huduma maalum zilizobinafsishwa kwa mahitaji yako. Jiunge na
            mtandao mpana wa zaidi ya watumiaji bilioni 1.5, na ujenge uhusiano
            wenye thamani na wigo mpana wa vyombo vya biashara, ikiwa ni pamoja
            na wasambazaji, watengenezaji, wauzaji nje, waagizaji, wauzaji wa
            jumla, wauzaji wa rejareja, watoaji leseni, wapokeaji leseni, na
            wawekezaji. 'TürkAfrica.Market' inasimama kama lango lako kuelekea
            ulimwengu wa fursa, kukuza uhusiano na ukuaji katika soko la
            kimataifa lenye dynamism.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section8;
