import React from "react";
import "./sec6.css";
import sec6img from "../../../../assets/sec_6.png";
import applelogo from "../../../../assets/Applestore.png";
import playlogo from "../../../../assets/G_play.png";
const Section6 = () => {
  return (
    <div className="section6">
      <div className="submain_sec6_div">
        <div className="section6_img_div">
          <img src={sec6img} alt="" />
        </div>
        <div className="section6_text_div">
          <h1>
            Achetez simplement <br /> en quelques clics !
          </h1>
          <p>
            Découvrez la commodité d'acheter sur TürkAfrica.Market en seulement
            quelques clics simples ! Nous vous invitons chaleureusement à
            explorer notre sélection et à profiter d'une expérience d'achat
            fluide et sans tracas. Bon shopping !
          </p>
          <div className="store_div">
            <p>Application Bientôt Disponible sur</p>
            <div className="store_img">
              <img src={applelogo} alt="" />
              <img src={playlogo} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section6;
