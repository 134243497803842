import React from "react";
import "./sec5.css";
import sec5img from "../../../../assets/sec_5.png";

const Section5 = () => {
  return (
    <div className="section2" id="Sec5">
      <div className="submain_sec5">
        <h1>Lengo Letu</h1>
        <div className="submain_div_sec2">
          <div className="sec2_textbox">
            <p>
              Lengo letu ni kuimarisha na kupanua uhusiano wa kibiashara na
              kiuchumi kati ya Uturuki na bara la Afrika. Ili kufanikisha hili,
              tumeanzisha <span>TurkAfrica.Market</span> kama jukwaa kuu,
              lililojitolea kukuza mahusiano madhubuti ya biashara kwa mtumiaji
              (B2C). Kituo cha maono yetu ni lengo la kuunganisha biashara za
              Uturuki na Afrika kupitia soko la biashara la mtandaoni
              linalovumbua, ambalo linawezesha mwingiliano wa moja kwa moja kati
              ya biashara na watumiaji katika kanda hizi zinazokua haraka.
            </p>

            <p>
              Tumejitolea kufungua fursa mpya za ukuaji, kukuza ushirikiano wa
              kimkakati, na kuunda thamani ya pande zote. Jukwaa letu la kisasa
              limeundwa kwa uangalifu ili kukidhi mahitaji yanayobadilika ya
              masoko ya Uturuki na Afrika, kuwawezesha wafanyabiashara kustawi
              katika uchumi huu wa kimataifa wenye nguvu na unaoendelea
              kuunganishwa.
            </p>
          </div>
          <div className="sec5_img_box">
            <img src={sec5img} alt="Not found" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section5;
