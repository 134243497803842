import React from "react";
import "./Thankyou.css";
import checkimg from "../../assets/check.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Thankyou = () => {
  const navigate = useNavigate();

  const route = useLocation();
  const name = route.state?.checkPathname;
  // console.log("check path name ", name);

  return (
    <>
      <div class="main-sec">
        <div class="text">
          <img src={checkimg} alt="" />
          {name == "/Turkish" ? (
            <h1>Bize Ulaştığınız İçin Teşekkür Ederiz</h1>
          ) : name == "/French" ? (
            <h1>Merci de nous avoir contactés </h1>
          ) : name == "/Portuguese" ? (
            <h1>Obrigado por entrar em contato conosco</h1>
          ) : name == "/Spanish" ? (
            <h1>Gracias por contactarnos</h1>
          ) : name == "/Swahili" ? (
            <h1>Asante kwa Kutufikia</h1>
          ) : (
            <h1>Thank You For Contacting Us</h1>
          )}
          {name == "/Turkish" ? (
            <p>
              Bize yazmak için zaman ayırdığınızı takdir ediyoruz. <br />
              Çok yakında size geri döneceğiz.
            </p>
          ) : name == "/French" ? (
            <p>
              Nous apprécions que vous ayez pris le temps de nous écrire.
              <br />
              Nous vous recontacterons très bientôt.
            </p>
          ) : name == "/Portuguese" ? (
            <p>
              Agradecemos por ter dedicado tempo para nos escrever.
              <br />
              Retornaremos o seu contato muito em breve.
            </p>
          ) : name == "/Spanish" ? (
            <p>
              Apreciamos que se haya tomado el tiempo para escribirnos. <br />
              Nos pondremos en contacto con usted muy pronto.
            </p>
          ) : name == "/Swahili" ? (
            <p>
              Tunathamini kwamba umetenga muda kwa kutuandikia. <br />
              Tutakujibu hivi karibuni.
            </p>
          ) : (
            <p>
              We appreciate that you've taken the time to write us. <br />
              We'll get back to you very soon.
            </p>
          )}

          {/* <button onClick={check}>check </button> */}
          <Link
            // onClick={
            //   name == "/Turkish"
            //     ? navigate("/Turkish")
            //     : name == "/French"
            //     ? navigate("/French")
            //     : name == "/Portuguese"
            //     ? navigate("/Portuguese")
            //     : name == "/Spanish"
            //     ? navigate("/Spanish")
            //     : name == "/Swahili"
            //     ? navigate("/Swahili")
            //     : navigate("/")
            // }
            to={name}
            className="button_back">
            {name == "/Turkish"
              ? "Web sitesine geri dönün"
              : name == "/French"
              ? "Retournez au site Web"
              : name == "/Portuguese"
              ? "Volte para o site"
              : name == "/Spanish"
              ? "Regresa al sitio web"
              : name == "/Swahili"
              ? "Rudi kwenye Tovuti"
              : "Go to Website"}
          </Link>
          {/* <Link onClick={() => navigate(name)} className="button_back">
            {name === "/Turkish"
              ? "Web Sitesine Git"
              : name === "/French"
              ? "Aller sur un site Web"
              : name === "/Portuguese"
              ? "Acesse o site"
              : name === "/Spanish"
              ? "Ir a un sitio web"
              : name === "/Swahili"
              ? "Nenda kwenye tovuti"
              : "Go to Website"}
          </Link> */}
        </div>
      </div>
    </>
  );
};

export default Thankyou;
