import React from "react";
import "./sec1.css";

const Section1 = () => {
  return (
    <div className="section_one" id="Sec1">
      <div className="submain_div">
        <div className="inner_text_div">
          {/* <h1>
            TürkAfrica.Market<span>&#174;</span>'a
          </h1> */}
          <span>
            <h1>TürkAfrica.Market'</h1>a Hoş Geldiniz{" "}
          </span>
          {/*  */}
          <h4>Uygun Fiyatlı Yüksek Kaliteli Ürünler & Hizmetler</h4>
        </div>
      </div>
    </div>
  );
};

export default Section1;
