import React from "react";
import "./sec5.css";
import sec5img from "../../../../assets/sec_5.png";

const Section5 = () => {
  return (
    <div className="section2" id="Sec5">
      <div className="submain_sec5">
        <h1>Misyonumuz</h1>
        <div className="submain_div_sec2">
          <div className="sec2_textbox">
            <p>
              Misyonumuz, Türkiye ile Afrika kıtası arasındaki ticari ve
              ekonomik bağları güçlendirmek ve genişletmektir. Bu hedefe ulaşmak
              için <span>TurkAfrica.Market</span>'i önde gelen bir platform
              olarak kurduk, sağlam işletmeden tüketiciye (B2C) ilişkilerini
              geliştirmeye adanmış durumdayız. Vizyonumuzun merkezinde, Türk ve
              Afrika işletmelerini yenilikçi bir e-ticaret pazarı aracılığıyla
              birbirine bağlama hedefi yer almakta, bu hızla büyüyen bölgeler
              arasında işletmeler ve tüketiciler arasında kesintisiz
              etkileşimler sağlıyoruz.
            </p>

            <p>
              Büyüme için yeni fırsatların kapılarını açmaya, işbirlikçi
              ortaklıkları teşvik etmeye ve karşılıklı değer yaratmaya
              kararlıyız. Gelişmiş platformumuz, hem Türk hem de Afrika
              pazarlarının gelişen ihtiyaçlarına titizlikle uyarlanmış olup,
              işletmelerin bu dinamik ve giderek daha birbirine bağlı küresel
              ekonomide başarılı olmasını sağlamaktadır.
            </p>
          </div>
          <div className="sec5_img_box">
            <img src={sec5img} alt="Not found" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section5;
