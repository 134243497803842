import React from "react";
import "./sec6.css";
import sec6img from "../../../../assets/sec_6.png";
import applelogo from "../../../../assets/Applestore.png";
import playlogo from "../../../../assets/G_play.png";
const Section6 = () => {
  return (
    <div className="section6">
      <div className="submain_sec6_div">
        <div className="section6_img_div">
          <img src={sec6img} alt="" />
        </div>
        <div className="section6_text_div">
          <h1>
            Simply buy with <br /> a few clicks!
          </h1>
          <p>
            Discover the convenience of shopping at <b>TürkAfrica.Market </b>
            with just a few simple clicks! We warmly invite you to explore our
            selection and enjoy a smooth, hassle-free shopping experience. Happy
            Shopping!
          </p>
          <div className="store_div">
            <p>App Coming Soon on</p>
            <div className="store_img">
              <img src={applelogo} alt="" />
              <img src={playlogo} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section6;
