import React from "react";
import "./sec3.css";
import img1 from "../../../../assets/sec_3_1.png";
import img2 from "../../../../assets/sec_3_2.png";
import img3 from "../../../../assets/sec_3_3.png";
import img4 from "../../../../assets/sec_3_4.png";
import img5 from "../../../../assets/sec_3_5.png";
import img6 from "../../../../assets/sec_3_6.png";
import img7 from "../../../../assets/sec_3_7.png";
import img8 from "../../../../assets/sec_3_8.png";
import img9 from "../../../../assets/sec_3_9.png";
import img10 from "../../../../assets/sec_3_10.png";
import img11 from "../../../../assets/sec_3_11.png";
import img12 from "../../../../assets/sec_3_12.png";
import applelogo from "../../../../assets/Applestore.png";
import playlogo from "../../../../assets/G_play.png";
import { Link } from "react-scroll";

const Section3 = () => {
  const data = [
    { id: 1, img: img2, name: "Compagnie minière" },
    { id: 2, img: img3, name: "Investisseur" },
    { id: 3, img: img4, name: "Producteur de café" },
    { id: 4, img: img9, name: "Importateur de café" },
    { id: 5, img: img8, name: "Détaillant" },
    { id: 6, img: img11, name: "Clinique de beauté" },
    { id: 7, img: img12, name: "Entreprise agricole" },
    { id: 8, img: img7, name: "Pièces détachées automobiles" },
    { id: 9, img: img10, name: "Entreprise de design intérieur et extérieur" },
    { id: 10, img: img6, name: "Grossiste en fabrication textile”" },
    { id: 11, img: img5, name: "Avocat d'affaires / Avocat d’entreprise" },
    { id: 12, img: img1, name: "Fabricant de décoration et de meubles" },
  ];
  return (
    <div className="Section3" id="Sec3">
      <div className="sub_sec3">
        <div className="sec3img_div">
          {data.map((item, i) => {
            return (
              <div className="details_img_box">
                <img src={item.img} alt="" />
                <div className="nametext_div">
                  <h4>{item.name}</h4>
                </div>
              </div>
            );
          })}
        </div>
        <div className="sec3text_div">
          <h1>Obtenez un Accès Premium</h1>
          <p>
            "Réseautez, concluez des accords et établissez des partenariats avec
            des distributeurs, des fabricants, des exportateurs, des
            importateurs, des investisseurs, des concédants de licence, des
            licenciés, des grossistes et des détaillants. De plus, vous pouvez
            vendre vos produits ou offrir vos services directement à plus de 1,5
            milliard de consommateurs via notre plateforme de commerce
            électronique à venir, <b> TürkAfrica.Market</b> , sans aucun coût.
          </p>
          {/* <h4>Liste d'attente d'inscription avant lancement</h4> */}
          <Link className="PRE_btn" to="Contact" offset={-100}>
            Inscription Gratuite ici
          </Link>

          {/* <div className="store_div">
            <p>Application Bientôt Disponible sur</p>
            <div className="store_img">
              <img src={applelogo} alt="" />
              <img src={playlogo} alt="" />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Section3;
