import React from "react";
import "./Newsection.css";
import sec6img from "../../../../assets/Newsection.png";
import applelogo from "../../../../assets/Apple_black.png";
import playlogo from "../../../../assets/Google_black.png";

const Index = () => {
  return (
    <div className="Newsection" id="NewSection">
      <div className="submain_Newsection_div">
        <div className="Newsection_img_div">
          <img src={sec6img} alt="" />
        </div>
        <div className="Newsection_text_div">
          <h1>
            Hisse Sahipliği ve Yatırım <br /> Fırsatları
          </h1>
          <p>
            Eğer değerli bir hissedar olma fırsatlarını keşfetmeye veya{" "}
            <b>TurkAfrica.Market</b>'ya yatırım yapmaya ilgi duyuyorsanız,
            sizleri bizimle iletişime geçmeye sıcak bir şekilde davet ediyoruz.
            Daha fazla detay ve görüşme başlatmak için lütfen 'İletişim'
            bölümüne gidiniz. Sizlerle bağlantı kurma fırsatını sabırsızlıkla
            bekliyoruz.
          </p>
          <div className="Newsection_store_div">
            <p>Uygulama Yakında</p>
            <div className="store_img">
              <img src={applelogo} alt="" />
              <img src={playlogo} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
