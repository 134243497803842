import React from "react";
import "./sec3.css";
import img1 from "../../../../assets/sec_3_1.png";
import img2 from "../../../../assets/sec_3_2.png";
import img3 from "../../../../assets/sec_3_3.png";
import img4 from "../../../../assets/sec_3_4.png";
import img5 from "../../../../assets/sec_3_5.png";
import img6 from "../../../../assets/sec_3_6.png";
import img7 from "../../../../assets/sec_3_7.png";
import img8 from "../../../../assets/sec_3_8.png";
import img9 from "../../../../assets/sec_3_9.png";
import img10 from "../../../../assets/sec_3_10.png";
import img11 from "../../../../assets/sec_3_11.png";
import img12 from "../../../../assets/sec_3_12.png";
import applelogo from "../../../../assets/Applestore.png";
import playlogo from "../../../../assets/G_play.png";
import { Link } from "react-scroll";

const Section3 = () => {
  const data = [
    { id: 1, img: img2, name: "Madencilik Şirketi" },
    { id: 2, img: img3, name: "Yatırımcı" },
    { id: 3, img: img4, name: "Kahve Üreticisi" },
    { id: 4, img: img9, name: "Kahve İthalatçısı" },
    { id: 5, img: img8, name: "Perakendeci" },
    { id: 6, img: img11, name: "Güzellik Kliniği" },
    { id: 7, img: img12, name: "Tarım İşletmesi" },
    { id: 8, img: img7, name: "Otomotiv Yedek Parçaları" },
    { id: 9, img: img10, name: "İç ve Dış Tasarım Şirketi" },
    { id: 10, img: img6, name: "Tekstil İmalatı Toptancısı" },
    { id: 11, img: img5, name: "İş Hukukçusu/ Kurumsal Avukat" },
    { id: 12, img: img1, name: "Dekorasyon ve Mobilya Üreticisi" },
  ];
  return (
    <div className="Section3" id="Sec3">
      <div className="sub_sec3">
        <div className="sec3img_div">
          {data.map((item, i) => {
            return (
              <div className="details_img_box">
                <img src={item.img} alt="" />
                <div className="nametext_div">
                  <h4>{item.name}</h4>
                </div>
              </div>
            );
          })}
        </div>
        <div className="sec3text_div">
          <h1 className="sec3h1">Premium Erişim Elde Edin</h1>
          <p>
            Ağ kurun, anlaşmalar yapın ve dağıtımcılar, üreticiler,
            ihracatçılar, ithalatçılar, yatırımcılar, lisans verenler, lisans
            alanlar, toptancılar ve perakendeciler ile ortaklık yapın. Ek
            olarak, yakında açılacak olan e-ticaret platformumuz
            <b>TürkAfrica.Market</b> aracılığıyla 1,5 milyardan fazla tüketiciye
            ürünlerinizi satabilir veya hizmetlerinizi doğrudan sunabilirsiniz,
            ve bu hiçbir maliyet gerektirmeyecek.
          </p>
          {/* <h4> Ön Lansman Kayıt Bekleme Listesi</h4> */}
          <Link className="PRE_btn" to="Contact" offset={-100}>
            Ücretsiz Kayıt Burada
          </Link>

          {/* <div className="store_div">
            <p>Uygulama Yakında Burada</p>
            <div className="store_img">
              <img src={applelogo} alt="" />
              <img src={playlogo} alt="" />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Section3;
