import React from "react";
import "./sec1.css";

const Section1 = () => {
  return (
    <div className="section_one" id="Sec1">
      <div className="submain_div">
        <div className="inner_text_div">
          <span>
            Karibu kwenye
            <h1 style={{ marginLeft: "15px" }}> TürkAfrica.Market</h1>
          </span>
          {/* <h3>Welcome to</h3>
          <h1>
            TürkAfrica.Market <span>&#174;</span>
          </h1> */}
          <h4>Bidhaa na Huduma za Ubora wa Juu kwa Bei Nafuu</h4>
        </div>
      </div>
    </div>
  );
};

export default Section1;
