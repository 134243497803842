import React from "react";
import "./sec8.css";
import sec8img from "../../../../assets/sec_8.png";
import applelogo from "../../../../assets/Apple_store.png";
import playlogo from "../../../../assets/G_play.png";

const Section8 = () => {
  return (
    <div className="Section8" id="Sec8">
      <div className="section8_text_div">
        <div className="heading_text">
          <h1>
            Baixe o App TürkAfrica<span>&#174;</span> Mercado
          </h1>
          <p>Produtos e Serviços de Alta Qualidade a Preços Acessíveis</p>
        </div>
        <div className="Section8_img">
          <img src={sec8img} alt="" />
        </div>
        <div className="store_div">
          <p style={{ color: "black", textAlign: "center" }}>
            Aplicativo Em Breve Disponível em
          </p>
          <div
            className="store_img"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <img src={applelogo} alt="" />
            <img src={playlogo} alt="" />
          </div>
        </div>
        <div className="bottom_text_div">
          <p>
            Descubra um mercado abrangente onde você pode adquirir uma extensa
            gama de produtos essenciais tanto para suas necessidades
            profissionais quanto pessoais. Conecte-se com uma diversidade de
            profissionais altamente qualificados, cada um oferecendo serviços
            especializados de acordo com as suas necessidades. Conecte-se com
            uma ampla rede de mais de 1,5 bilhão de consumidores e estabeleça
            relações valiosas com uma vasta gama de entidades comerciais,
            incluindo distribuidores, fabricantes, exportadores, importadores,
            atacadistas, varejistas, licenciadores, licenciados e investidores.{" "}
            <b>'TürkAfrica.Market'</b>
            representa o seu portal para um mundo de oportunidades, fomentando
            conexões e crescimento num mercado global dinâmico.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Section8;
