import React from "react";
import "./sec4.css";
import applelogo from "../../../../assets/Apple_store.png";
import playlogo from "../../../../assets/G_play.png";
import imgone from "../../../../assets/sec_4_1.png";
import imgtwo from "../../../../assets/sec_4_2.png";
import imgthree from "../../../../assets/sec_4_3.png";
import imgfour from "../../../../assets/sec_4_4.png";
import imgfive from "../../../../assets/sec_4_5.png";
import imgsix from "../../../../assets/sec_4_6.png";
import { Link } from "react-scroll";

const Section4 = () => {
  return (
    <div className="Section4" id="sec4">
      <div className="sub_sec4">
        <div className="sec4_text_main_div">
          <h1>Ön Lansman Kaydı</h1>
          <Link className="PRE_btn1" to="Contact" offset={-100}>
            Ücretsiz Kayıt Burada
          </Link>
          <h3>Ücretsiz Erişim Hesabı</h3>
          <p>
            Türkiye'de üretilen 'Uygun Fiyatlı Yüksek Kaliteli Ürünler &
            Hizmetler'i keşfedin. Üreticilerden, toptancılardan ve
            perakendecilerden doğrudan satın alın. Hem erkekler hem de kadınlar
            için kapsamlı güzellik tedavileri sunan tıbbi hizmetlerle, güzellik
            klinikleriyle iletişime geçin ve <b>TürkAfrica.Market</b>'te çok
            daha fazlasıyla sohbet edin.
          </p>
          <div className="store_div">
            <p style={{ color: "white", textAlign: "center" }}>
              Uygulama Yakında Burada
            </p>
            <div
              className="store_img"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <img src={applelogo} alt="" />
              <img src={playlogo} alt="" />
            </div>
          </div>
        </div>
        <div className="sec4_img_main_div">
          <div className="firstimg_div">
            <img src={imgone} alt="" />
            <div className="sub_first_div">
              <img src={imgtwo} alt="" />
              <img src={imgthree} alt="" />
            </div>
          </div>
          <div className="firstimg_div">
            <img src={imgfour} alt="" />
            <div className="sub_first_div">
              <img src={imgfive} alt="" />
              <img src={imgsix} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section4;
