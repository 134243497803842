import React from "react";
import "./sec2.css";
import sec2img from "../../../../assets/sec_2.png";

const Section2 = () => {
  return (
    <div className="section2" id="Sec2">
      <div className="submain_sec2">
        <h1>Sobre nosotros</h1>

        <div className="submain_div_sec2">
          <div className="sec2_textbox">
            <p>
              Bienvenido a <span>TurkAfrica.Market</span>, una plataforma
              avanzada de comercio electrónico diseñada para facilitar el
              comercio dinámico entre empresas turcas y africanas. Cerramos la
              brecha entre estas dos regiones vibrantes, creando un mercado B2C
              que permite a los emprendedores y empresas mostrar y vender sus
              productos a través de las fronteras.
            </p>

            <p>
              Para las <span>empresas y emprendedores turcos</span>,
              TurkAfrica.Market ofrece la oportunidad de acceder al mercado
              africano en rápido crecimiento. Nuestra plataforma permite que las
              empresas turcas presenten productos y servicios de alta calidad y
              asequibles - desde textiles, electrónicos y electrodomésticos
              hasta materiales de construcción y productos de salud -
              directamente a los consumidores africanos. Al eliminar las
              barreras tradicionales, las empresas turcas pueden establecer
              relaciones comerciales a largo plazo, aumentar el reconocimiento
              de su marca y proporcionar soluciones personalizadas para
              satisfacer las necesidades en evolución de la creciente clase
              media africana.
            </p>
          </div>
          <div className="sec2_img_box">
            <img src={sec2img} alt="Not found" />
          </div>
        </div>
      </div>

      <div className="Sec2_bottom_text">
        <p>
          Por otro lado, las <span>empresas africanas</span> se benefician de
          acceso directo a compradores turcos e internacionales a través de
          TurkAfrica.Market. Los productores de café, cacao, especias, textiles,
          artesanía y mucho más pueden mostrar sus productos auténticos a una
          audiencia más amplia, aumentando sus márgenes de beneficio al eludir
          intermediarios. Nuestra plataforma promueve un mayor alcance de
          mercado, alentando a los emprendedores africanos a hacer crecer sus
          negocios y competir globalmente, mientras construyen relaciones
          comerciales sostenibles con empresas turcas.
        </p>
        <p>
          En <span>TurkAfrica.Market</span>, estamos dedicados a fomentar una
          relación comercial recíproca y próspera entre Turquía y África,
          creando nuevas oportunidades de crecimiento, colaboración y éxito para
          las empresas y los consumidores.
        </p>
      </div>
    </div>
  );
};

export default Section2;
