import React from "react";
import "./sec7.css";
import sec7img from "../../../../assets/sec_7.png";
import applelogo from "../../../../assets/Applestore.png";
import playlogo from "../../../../assets/G_play.png";
const Section7 = () => {
  return (
    <div className="Section7">
      <div className="submain_sec7_div">
        <div className="section7_text_div">
          <h1>
            Recevez simplement votre
            <br /> colis avec facilité !
          </h1>
          <p>
            Profitez de la simplicité de récupérer votre colis ! Une fois que
            votre colis arrive à notre centre logistique, nous vous enverrons
            rapidement un message pour venir le récupérer. Nous nous engageons à
            rendre votre expérience de livraison aussi facile et pratique que
            possible.
          </p>
          <div className="store_div">
            <p>Application Bientôt Disponible sur</p>
            <div className="store_img">
              <img src={applelogo} alt="" />
              <img src={playlogo} alt="" />
            </div>
          </div>
        </div>
        <div className="section7_img_div">
          <img src={sec7img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Section7;
