import React from "react";
import "./sec2.css";
import sec2img from "../../../../assets/sec_2.png";

const Section2 = () => {
  return (
    <div className="section2" id="Sec2">
      <div className="submain_sec2">
        <h1>About US</h1>

        <div className="submain_div_sec2">
          <div className="sec2_textbox">
            <p>
              Welcome to <span>TurkAfrica.Market</span>, an advanced e-commerce
              platform designed to facilitate dynamic trade between Turkish and
              African businesses. We bridge the gap between these two vibrant
              regions, creating a seamless B2C marketplace that empowers
              entrepreneurs and companies to showcase and sell their products
              across borders.
            </p>

            <p>
              For <span>Turkish businesses and entrepreneurs</span> ,
              TurkAfrica.Market offers an opportunity to tap into the rapidly
              growing African market. Our platform enables Turkish companies to
              introduce their high-quality, affordable goods and
              services—ranging from textiles, electronics, and home appliances
              to construction materials and healthcare products—directly to
              African consumers. By eliminating traditional barriers, Turkish
              businesses can establish long-term trade ties, increase brand
              awareness, and provide tailored solutions to meet the evolving
              needs of Africa's expanding middle class.
            </p>
          </div>
          <div className="sec2_img_box">
            <img src={sec2img} alt="Not found" />
          </div>
        </div>
      </div>

      <div className="Sec2_bottom_text">
        <p>
          On the other side, <span>African businesses</span> benefit from direct
          access to Turkish and international buyers through TurkAfrica.Market.
          Producers of coffee, cocoa, spices, textiles, crafts, and more can
          showcase their authentic goods to a wider audience, boosting profit
          margins by bypassing intermediaries. Our platform fosters greater
          market reach, encouraging African entrepreneurs to grow their
          businesses and compete globally, while building sustainable trade
          relationships with Turkish businesses.
        </p>
        <p>
          At <span>TurkAfrica.Market</span> , we are dedicated to fostering a
          reciprocal and prosperous trade relationship between Turkey and
          Africa, creating new avenues for growth, collaboration, and success
          for businesses and consumers alike.
        </p>
      </div>
    </div>
  );
};

export default Section2;

// {

// At TurkAfrica.Market, we are dedicated to fostering a reciprocal and prosperous trade
// relationship between Turkey and Africa, creating new avenues for growth, collaboration, and
// success for businesses and consumers alike.
// }
