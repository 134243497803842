import React from "react";
import "./sec2.css";
import sec2img from "../../../../assets/sec_2.png";

const Section2 = () => {
  return (
    <div className="section2" id="Sec2">
      <div className="submain_sec2">
        <h1>Hakkımızda</h1>

        <div className="submain_div_sec2">
          <div className="sec2_textbox">
            <p>
              <span>TurkAfrica.Market</span>'e hoş geldiniz! Türk ve Afrika
              işletmeleri arasında dinamik ticareti kolaylaştırmak için
              tasarlanmış ileri düzey bir e-ticaret platformu. Bu iki canlı
              bölge arasındaki boşluğu doldurarak girişimcilerin ve şirketlerin
              sınır ötesi ürünlerini sergileyip satmalarını sağlıyoruz.
            </p>

            <p>
              <span>Türk işletmeleri ve girişimciler</span> için,
              TurkAfrica.Market, hızla büyüyen Afrika pazarına erişim fırsatı
              sunar. Tekstilden elektroniğe, ev aletlerinden inşaat malzemeleri
              ve sağlık ürünlerine kadar Türk şirketlerinin kaliteli ve uygun
              fiyatlı ürünlerini doğrudan Afrikalı tüketicilere sunmalarına
              olanak tanıyoruz. Geleneksel engelleri ortadan kaldırarak, Türk
              işletmeleri uzun vadeli ticaret ilişkileri kurabilir, marka
              bilinirliğini artırabilir ve Afrika'nın genişleyen orta sınıfının
              gelişen ihtiyaçlarına özel çözümler sunabilir.
            </p>
          </div>
          <div className="sec2_img_box">
            <img src={sec2img} alt="Not found" />
          </div>
        </div>
      </div>

      <div className="Sec2_bottom_text">
        <p>
          Diğer tarafta, <span>Afrika işletmeleri</span> , TurkAfrica.Market
          aracılığıyla doğrudan Türk ve uluslararası alıcılara erişim
          sağlamaktadır. Kahve, kakao, baharat, tekstil ve el sanatları gibi
          otantik ürünlerini daha geniş bir kitleye sergileyerek, aracıları
          ortadan kaldırarak kâr marjlarını artırıyorlar. Platformumuz daha
          geniş bir pazar erişimi sağlamakta ve Afrika girişimcilerini işlerini
          büyütmeye ve küresel ölçekte rekabet etmeye teşvik etmektedir. Bu
          sırada, Türk işletmeleri ile sürdürülebilir ticaret ilişkileri
          kuruyorlar.
        </p>
        <p>
          <span>TurkAfrica.Market</span> olarak, Türkiye ile Afrika arasında
          karşılıklı ve müreffeh bir ticaret ilişkisi oluşturmaya, işbirliği ve
          büyüme için yeni fırsatlar yaratmaya kendimizi adadık.
        </p>
      </div>
    </div>
  );
};

export default Section2;
