import React from "react";
import "./sec6.css";
import sec6img from "../../../../assets/sec_6.png";
import applelogo from "../../../../assets/Applestore.png";
import playlogo from "../../../../assets/G_play.png";
const Section6 = () => {
  return (
    <div className="section6">
      <div className="submain_sec6_div">
        <div className="section6_img_div">
          <img src={sec6img} alt="" />
        </div>
        <div className="section6_text_div">
          <h1>
            Nunua kwa Urahisi <br /> kwa Bofya Chache Tu!
          </h1>
          <p>
            Gundua urahisi wa kununua kwenye TürkAfrica.Market kwa bofya chache
            rahisi tu! Tunakukaribisha kwa moyo mkunjufu uchunguze uteuzi wetu
            na ufurahie uzoefu wa manunuzi usio na usumbufu. Furaha ya Manunuzi!
          </p>
          <div className="store_div">
            <p> App Inakuja Hivi Karibuni kwenye</p>
            <div className="store_img">
              <img src={applelogo} alt="" />
              <img src={playlogo} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section6;
