import React from "react";
import "./sec7.css";
import sec7img from "../../../../assets/sec_7.png";
import applelogo from "../../../../assets/Applestore.png";
import playlogo from "../../../../assets/G_play.png";
const Section7 = () => {
  return (
    <div className="Section7">
      <div className="submain_sec7_div">
        <div className="section7_text_div">
          <h1>
            ¡Recibe tu paquete
            <br /> fácilmente!
          </h1>
          <p>
            Disfruta de la simplicidad de recoger tu paquete. Una vez que tu
            paquete llegue a nuestro centro logístico, te enviaremos rápidamente
            un mensaje para que vengas a recogerlo. Estamos dedicados a asegurar
            que tu experiencia de entrega sea lo más sencilla y conveniente
            posible.
          </p>
          <div className="store_div">
            <p>Aplicación Próximamente en</p>
            <div className="store_img">
              <img src={applelogo} alt="" />
              <img src={playlogo} alt="" />
            </div>
          </div>
        </div>
        <div className="section7_img_div">
          <img src={sec7img} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Section7;
