import { createContext, useState } from "react";

export const GlobalContext = createContext();

const GlobalContextProvider = ({ children }) => {
  const [FirstModalShow, setFirstModalShow] = useState(false);
  const [SecondModalShow, setSecondModalShow] = useState(false);
  const [ThirdModalShow, setThirdModalShow] = useState(false);
  const [FourModalShow, setFourModalShow] = useState(false);

  return (
    <GlobalContext.Provider
      value={{
        FirstModalShow,
        setFirstModalShow,
        SecondModalShow,
        setSecondModalShow,
        ThirdModalShow,
        setThirdModalShow,
        FourModalShow,
        setFourModalShow,
      }}>
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
