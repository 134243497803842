import React from "react";
import "./sec5.css";
import sec5img from "../../../../assets/sec_5.png";

const Section5 = () => {
  return (
    <div className="section2" id="Sec5">
      <div className="submain_sec5">
        <h1>Notre Mission</h1>
        <div className="submain_div_sec2">
          <div className="sec2_textbox">
            <p>
              Notre mission est de renforcer et d'étendre les liens commerciaux
              et économiques entre la Turquie et le continent africain. Pour y
              parvenir, nous avons créé <span>TurkAfrica.Market</span>, une
              plateforme de premier plan dédiée à la promotion de relations
              solides entre les entreprises et les consommateurs (B2C). Au cœur
              de notre vision se trouve l'objectif de connecter les entreprises
              turques et africaines grâce à un marché de commerce électronique
              innovant, permettant des interactions fluides entre les
              entreprises et les consommateurs de ces régions en pleine
              croissance.
            </p>

            <p>
              Nous nous engageons à créer de nouvelles opportunités de
              croissance, à favoriser des partenariats collaboratifs et à
              générer une valeur mutuelle. Notre plateforme de pointe est
              méticuleusement conçue pour répondre aux besoins évolutifs des
              marchés turcs et africains, permettant aux entreprises de
              prospérer dans cette économie mondiale dynamique et de plus en
              plus interconnectée.
            </p>
          </div>
          <div className="sec5_img_box">
            <img src={sec5img} alt="Not found" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section5;
