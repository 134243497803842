import React from "react";
import "./sec4.css";
import applelogo from "../../../../assets/Apple_store.png";
import playlogo from "../../../../assets/G_play.png";
import imgone from "../../../../assets/sec_4_1.png";
import imgtwo from "../../../../assets/sec_4_2.png";
import imgthree from "../../../../assets/sec_4_3.png";
import imgfour from "../../../../assets/sec_4_4.png";
import imgfive from "../../../../assets/sec_4_5.png";
import imgsix from "../../../../assets/sec_4_6.png";
import { Link } from "react-scroll";

const Section4 = () => {
  return (
    <div className="Section4" id="sec4">
      <div className="sub_sec4">
        <div className="sec4_text_main_div">
          <h1>Usajili wa Kabla ya Uzinduzi</h1>
          <Link className="PRE_btn1" to="Contact" offset={-100}>
            Usajili Bure hapa
          </Link>
          <h3>Akaunti ya Upatikanaji Bure</h3>
          <p>
            Gundua 'Bidhaa na Huduma za Ubora wa Juu kwa Bei Nafuu'
            zilizotengenezwa Uturuki. Nunua moja kwa moja kutoka kwa
            watengenezaji, wauzaji wa jumla, na wauzaji wa rejareja. Wasiliana
            na huduma za matibabu, kliniki za urembo zinazotoa tiba kamili za
            urembo kwa wanaume kwa wanawake, na mengi zaidi kwenye
            TürkAfrica.Market
          </p>
          <div className="store_div">
            <p style={{ color: "white", textAlign: "center" }}>
              App Inakuja Hivi Karibuni
            </p>
            <div
              className="store_img"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <img src={applelogo} alt="" />
              <img src={playlogo} alt="" />
            </div>
          </div>
        </div>
        <div className="sec4_img_main_div">
          <div className="firstimg_div">
            <img src={imgone} alt="" />
            <div className="sub_first_div">
              <img src={imgtwo} alt="" />
              <img src={imgthree} alt="" />
            </div>
          </div>
          <div className="firstimg_div">
            <img src={imgfour} alt="" />
            <div className="sub_first_div">
              <img src={imgfive} alt="" />
              <img src={imgsix} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section4;
